import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { CookieService } from 'ngx-cookie';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LogoutModalComponent } from './logout-modal/logout-modal.component';
import { LayoutService } from '../../layout.service';
import { InvitationsComponent } from '../../invitations/invitations.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { DropdownComponent } from '../../../shared/components/dropdown/dropdown.component';
import { OrganizationSelectComponent } from './organization-select/organization-select.component';
import { NgClass, AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    OrganizationSelectComponent,
    DropdownComponent,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    AsyncPipe,
    TranslateModule,
    NgIf,
  ],
})
export class HeaderComponent implements OnInit {
  @Output() protected toggleSidenav: EventEmitter<void> = new EventEmitter<void>();

  @Input() showToggleSidenav: boolean;

  user: User;

  constructor(
    private _cookieService: CookieService,
    public auth: AuthService,
    private dialog: MatDialog,
    public layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    this.auth.user$.subscribe((user) => {
      this.user = user;
    });
  }

  changePassword() {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    this.dialog.open(ChangePasswordModalComponent, {
      data: { email: this.user.email },
    });
  }

  insertInvitationCode() {
    this.dialog.open(InvitationsComponent, { disableClose: true, data: {} });
  }

  onTransitionEnd() {
    dispatchEvent(new Event('resize'));
  }

  logout() {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    this.dialog.open(LogoutModalComponent);
  }

  emitToggleSidenav() {
    this.toggleSidenav.emit();
  }
}
