/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DashboardReportListResponseDto } from '../model/dashboardReportListResponseDto';
import { DashboardReportRequestDto } from '../model/dashboardReportRequestDto';
import { DbDefaultResponse } from '../model/dbDefaultResponse';
import { FileTypeDto } from '../model/fileTypeDto';
import { ListFilterRequestDto } from '../model/listFilterRequestDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class DashboardreportService {
  protected basePath = 'http://127.0.0.1:4201/api/';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dashboardReportControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public dashboardReportControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public dashboardReportControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public dashboardReportControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling dashboardReportControllerDelete.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbDefaultResponse>(
      'delete',
      `${this.basePath}/dashboard/report/report/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param id
   * @param file_format
   * @param timezone
   * @param lng
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dashboardReportControllerDirectDownloadReport(
    id: number,
    file_format: string,
    timezone: string,
    lng: string,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FileTypeDto>;
  public dashboardReportControllerDirectDownloadReport(
    id: number,
    file_format: string,
    timezone: string,
    lng: string,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FileTypeDto>>;
  public dashboardReportControllerDirectDownloadReport(
    id: number,
    file_format: string,
    timezone: string,
    lng: string,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FileTypeDto>>;
  public dashboardReportControllerDirectDownloadReport(
    id: number,
    file_format: string,
    timezone: string,
    lng: string,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling dashboardReportControllerDirectDownloadReport.',
      );
    }

    if (file_format === null || file_format === undefined) {
      throw new Error(
        'Required parameter file_format was null or undefined when calling dashboardReportControllerDirectDownloadReport.',
      );
    }

    if (timezone === null || timezone === undefined) {
      throw new Error(
        'Required parameter timezone was null or undefined when calling dashboardReportControllerDirectDownloadReport.',
      );
    }

    if (lng === null || lng === undefined) {
      throw new Error(
        'Required parameter lng was null or undefined when calling dashboardReportControllerDirectDownloadReport.',
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (file_format !== undefined && file_format !== null) {
      queryParameters = queryParameters.set('file_format', <any>file_format);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set('timezone', <any>timezone);
    }
    if (lng !== undefined && lng !== null) {
      queryParameters = queryParameters.set('lng', <any>lng);
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FileTypeDto>(
      'get',
      `${this.basePath}/dashboard/report/directDownload/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param id
   * @param file_format
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dashboardReportControllerDownloadReport(
    id: number,
    file_format: string,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FileTypeDto>;
  public dashboardReportControllerDownloadReport(
    id: number,
    file_format: string,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FileTypeDto>>;
  public dashboardReportControllerDownloadReport(
    id: number,
    file_format: string,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FileTypeDto>>;
  public dashboardReportControllerDownloadReport(
    id: number,
    file_format: string,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling dashboardReportControllerDownloadReport.',
      );
    }

    if (file_format === null || file_format === undefined) {
      throw new Error(
        'Required parameter file_format was null or undefined when calling dashboardReportControllerDownloadReport.',
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (file_format !== undefined && file_format !== null) {
      queryParameters = queryParameters.set('file_format', <any>file_format);
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FileTypeDto>(
      'get',
      `${this.basePath}/dashboard/report/download/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dashboardReportControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DashboardReportListResponseDto>;
  public dashboardReportControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DashboardReportListResponseDto>>;
  public dashboardReportControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DashboardReportListResponseDto>>;
  public dashboardReportControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling dashboardReportControllerList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DashboardReportListResponseDto>(
      'post',
      `${this.basePath}/dashboard/report/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dashboardReportControllerRequestReport(
    body: DashboardReportRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public dashboardReportControllerRequestReport(
    body: DashboardReportRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public dashboardReportControllerRequestReport(
    body: DashboardReportRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public dashboardReportControllerRequestReport(
    body: DashboardReportRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling dashboardReportControllerRequestReport.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/dashboard/report/request`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
