import { Component } from '@angular/core';
import { finalize, map, take } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { LayoutService } from '../../../../layout/layout.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { DisabledDirective } from '../../../../shared/directives/disabled.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { WaitService } from '../../../../shared/services/wait.service';
import domtoimage from 'dom-to-image';
import { DashboardTemplateService } from '../../services/dashboard-template.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { DashboardTemplatesSelectComponent } from './dashboard-templates-select/dashboard-templates-select.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DashboardDto } from '../../../../../api-main';
import { DashboardTemplateModalComponent } from './dashboard-template-modal/dashboard-template-modal.component';
import { PrivilegesService } from '../../../../shared/services/privileges.service';
import { LocaleSessionService } from '../../../../shared/services/localeSession.service';
import { downloadBlob } from '../../../reports/reports-list/downloadBlob';
import { DropdownComponent } from '../../../../shared/components/dropdown/dropdown.component';
import { b64toBlob } from '../../../reports/reports-list/b64toBlob';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    DisabledDirective,
    AsyncPipe,
    TranslateModule,
    NgIf,
    DashboardTemplatesSelectComponent,
    DropdownComponent,
  ],
})
export class DashboardHeaderComponent {
  get editMode(): boolean {
    return this.dashboardService.editMode$.value;
  }

  get isDownloading(): boolean {
    return this.dashboardService.dashboardIsDownloading();
  }

  get allowEdit(): boolean {
    return this.privilegesService.hasEditPrivileges(this.layoutService.selectedItem);
  }

  toggleEditMode(value: boolean = null) {
    if (value != null) this.dashboardService.editMode$.next(value);
    else this.dashboardService.editMode$.next(!this.editMode);
  }

  get changesMade() {
    return this.dashboardService.dashboardItemChanged;
  }

  private pngImage: string;

  dropDownStyles = {
    margin: 0,
    'min-width': 'unset',
  };

  constructor(
    private dashboardService: DashboardService,
    private dashboardTemplateService: DashboardTemplateService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private alertService: AlertService,
    private layoutService: LayoutService,
    private waitService: WaitService,
    private privilegesService: PrivilegesService,
    private localeService: LocaleSessionService,
  ) {}

  dashboardName$ = this.layoutService.selectedItem$?.pipe(
    map((selectedItem) => {
      return selectedItem?.item_name ?? '';
    }),
  );

  restore() {
    if (this.changesMade)
      this.dashboardService.restoreDashboard().subscribe(() => {
        this.dashboardService.editMode$.next(false);
      });
    else this.dashboardService.editMode$.next(false);
  }

  createPng() {
    //TODO download will have to be done on server
    // TODO handle changing dashboard during download (stop download?)
    this.dashboardService.dashboardIsDownloading.set(true);
    this.waitService.start();
    const container = <HTMLElement>document.querySelector('.dashboard-container');
    const content = <HTMLElement>document.querySelector('mat-card-content');

    const itemTop = Array.from(container.querySelectorAll('gridster-item'))
      .map((item) => (item ? item.getBoundingClientRect() : null))
      .map((o) => {
        if (!o) return null;
        return o.top + o.height;
      })
      .filter((o) => !!o)
      .sort((a, b) => a - b);

    const height = Math.max(...itemTop);
    container.style.height = height + 'px';

    return domtoimage
      .toPng(content)
      .then((value: string) => {
        this.pngImage = value;
        container.style.height = '100%';
      })
      .catch((error: any) => {
        console.error('Failed to generate dashboard', error);
      })
      .finally(() => {
        this.waitService.stop();
        this.dashboardService.dashboardIsDownloading.set(false);
      });
  }

  downloadPng() {
    this.createPng().then(() => {
      const itemName = this.layoutService.selectedItem.item_name ?? 'dashboard';
      const blob = b64toBlob(this.pngImage.split(',')[1], 'image/png');
      downloadBlob(blob, itemName, 'png');
    });
  }

  downloadPdf() {
    this.createPng().then(() => {
      const itemName = this.layoutService.selectedItem.item_name ?? 'dashboard';
      const doc = new jsPDF('p', 'mm', 'a4');
      const docWidth = doc.internal.pageSize.getWidth();
      const docHeight = doc.internal.pageSize.getHeight();
      doc.addImage(this.pngImage, 'png', 0, 0, docWidth, docHeight);
      doc.save(itemName);
    });
  }

  downloadCsv() {
    this.dashboardService.dashboardIsDownloading.set(true);
    this.waitService.start();
    this.dashboardService
      .directDownloadDashboard(
        this.dashboardService.dashboard.id,
        this.localeService.lang,
        this.localeService.timezone,
        'csv',
      )
      .pipe(
        finalize(() => {
          this.dashboardService.dashboardIsDownloading.set(false);
          this.waitService.stop();
        }),
      )
      .subscribe({
        next: (res) => {
          const itemName = this.layoutService.selectedItem.item_name ?? 'dashboard';
          const file = JSON.parse(atob(res.file)).csvBuffer.data;
          const str = new TextDecoder().decode(Uint8Array.from(file));
          const blobCSV = new Blob([str], { type: 'text/csv' });
          downloadBlob(blobCSV, itemName, 'csv');
        },
        error: (err) => {
          console.error('Error: ', err);
        },
      });
  }

  showTemplateSelect() {
    return !this.showSaveAsTemplate() && !this.dashboardService.editMode$.value;
  }

  showSaveAsTemplate() {
    return this.dashboardService.items?.length > 0;
  }

  private saveAsTemplate(dashboard: DashboardDto = null) {
    this.waitService.start();
    this.dashboardTemplateService
      .save(dashboard)
      .pipe(finalize(() => this.waitService.stop()))
      .subscribe({
        next: () => this.alertService.info(this.translate.instant('DASHBOARD.TEMPLATE_SAVED')),
        error: () => this.alertService.error(this.translate.instant('TEMPLATE.SELECT_ERROR')),
      });
  }

  openSaveTemplateModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      ...this.dashboardService.dashboard,
      name: this.layoutService.selectedItem.item_name,
    } as DashboardDto;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(DashboardTemplateModalComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: any) => {
        if (!data) return;
        // console.log('-> data', data);
        this.saveAsTemplate(data);
      });
  }
}
