import { DataRequestDto } from '../../../../../../api-main';
import { getAllBaseUnitsForSeries } from '../utils/getBaseUnitForScale';
import { UplotSeriesConfig } from '../models/UplotSeriesConfig.model';

const allowedScales = [
  DataRequestDto.ScaleEnum.FIFTEENMINUTES,
  DataRequestDto.ScaleEnum.HOURLY,
  DataRequestDto.ScaleEnum.DAILY,
  DataRequestDto.ScaleEnum.WEEKLY,
  DataRequestDto.ScaleEnum.MONTHLY,
  DataRequestDto.ScaleEnum.QUARTER,
  DataRequestDto.ScaleEnum.HALFYEARLY,
  DataRequestDto.ScaleEnum.YEARLY,
];

export function uplotGetScaleMode(rangeInHours): DataRequestDto.ScaleEnum {
  return allowedScales.find((scale, index, self) => {
    if (index == self.length - 1) return true; //this is last range

    return (
      rangeInHours >=
        (scale != DataRequestDto.ScaleEnum.FIFTEENMINUTES && scale != DataRequestDto.ScaleEnum.AUTO
          ? getScaleBreakPointInHours(self[index - 1])
          : 0) && rangeInHours < getScaleBreakPointInHours(scale)
    );
  });
}

export function isIntervalAvailableForRange(
  scale: DataRequestDto.ScaleEnum,
  rangeInHours: number,
): boolean {
  if (scale == DataRequestDto.ScaleEnum.AUTO) return true;
  const breakpoint = getScaleBreakPointInHours(scale);
  return breakpoint > rangeInHours / 2 && breakpoint < rangeInHours * 1000;
}

export function getScaleBreakPointInHours(scale: DataRequestDto.ScaleEnum) {
  return (getScaleValueInSeconds(scale) / 3600) * 1000;
}

export function getScaleValueInSeconds(scale: DataRequestDto.ScaleEnum) {
  if (scale == DataRequestDto.ScaleEnum.AUTO) throw new Error(`Auto scale doesn't have value`);

  switch (scale) {
    case DataRequestDto.ScaleEnum.FIFTEENMINUTES:
      return 15 * 60;
    case DataRequestDto.ScaleEnum.HOURLY:
      return 60 * 60;
    case DataRequestDto.ScaleEnum.DAILY:
      return getScaleValueInSeconds(DataRequestDto.ScaleEnum.HOURLY) * 24;
    case DataRequestDto.ScaleEnum.WEEKLY:
      return getScaleValueInSeconds(DataRequestDto.ScaleEnum.DAILY) * 7;
    case DataRequestDto.ScaleEnum.MONTHLY:
      return (getScaleValueInSeconds(DataRequestDto.ScaleEnum.DAILY) * 365) / 12;
    case DataRequestDto.ScaleEnum.QUARTER:
      return getScaleValueInSeconds(DataRequestDto.ScaleEnum.MONTHLY) * 3;
    case DataRequestDto.ScaleEnum.HALFYEARLY:
      return getScaleValueInSeconds(DataRequestDto.ScaleEnum.MONTHLY) * 6;
    case DataRequestDto.ScaleEnum.YEARLY:
      return getScaleValueInSeconds(DataRequestDto.ScaleEnum.MONTHLY) * 12;
  }
}

export function uplotGetScales(series: UplotSeriesConfig[] = null) {
  const baseScales = {
    x: {
      minRange: 3600 * 12,
      maxRange: 3600 * 24 * 365 * 5,
      time: true,
      range: (u, min, max) => {
        const minimalRange = u.scales.x.minRange; //12 hours
        const maxRange = u.scales.x.maxRange;

        const range = max - min;

        if (range < minimalRange) {
          const diff = (minimalRange - range) / 2;
          return [min - diff, max + diff];
        }
        if (range > maxRange) {
          const diff = (range - maxRange) / 2;
          return [min + diff, max - diff];
        }
        return [min, max];
      },
    },
    y: {
      range: yScaleRange,
    },
  };
  if (series != null) {
    const allUnits = getAllBaseUnitsForSeries();
    allUnits.forEach((unit) => {
      baseScales[unit] = { range: yScaleRange };
    });
  }

  return baseScales;
}

export function yScaleRange(u, min, max, scaleKey) {
  if (u.scales[scaleKey].userSet === true) return [u.scales[scaleKey].min, u.scales[scaleKey].max];
  const defOffset = 1;

  const offset = 5 / 100;
  let nMin, nMax;

  // if (+min == 0 && +max == 0) nMin = -defOffset;
  if (+min == 0 && +max == 0) nMin = 0;
  else nMin = min > 0 ? min * (1 - offset) : min * (1 + offset);

  if (+max == 0) nMax = defOffset;
  else nMax = max > 0 ? max * (1 + offset) : max * (1 - offset);

  return [nMin, nMax];
}
