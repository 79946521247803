import { Injectable, signal } from '@angular/core';
import { DisplayGrid, GridsterConfig } from 'angular-gridster2';
import { getDefaultGridsterConfig } from '../utils/getDefaultGridsterConfig';
import {
  BehaviorSubject,
  catchError,
  Observable,
  of,
  ReplaySubject,
  skip,
  Subject,
  switchMap,
} from 'rxjs';
import { DashboardItem } from '../models/DashboardItem';
import { DashboardDto, DashboardUpdateDto } from '../../../../api-main';
import { deepEquals } from '../../../shared/utils/deepEquals';
import { DashboardApiService } from './dashboard-api.service';
import { restoreDashboardItemArray } from '../utils/restoreDashboardItemArray';
export type ItemChangeData = {
  id: string;
  mode: string;
  value: string | number;
};
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  readonly editMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly selectedItem$ = new BehaviorSubject<DashboardItem>(null);

  readonly selectedLayerId$ = new BehaviorSubject<string>(null);

  //Provide true if settings should be shown and false for position only if visible
  readonly triggerSettings$ = new Subject<boolean>();

  //Called when gridster options are changed
  readonly optionsChanged$ = new Subject<void>();

  //Called when gridster options are changed TODO is it even used??
  readonly itemChanged$ = new Subject<ItemChangeData | void>();

  //Used to cancel requests for current dashboard (called before leaving and saving dashboard)
  readonly leavingCurrentDashboard$ = new Subject<void>();

  //Reply to update if component wasn't created yet
  readonly updateFavDashboards$ = new ReplaySubject<void>(1);

  readonly options: GridsterConfig = this.gridsterConfig;

  dashboardIsDownloading = signal(false);

  dashboards: Array<DashboardDto> = [];

  dashboard: DashboardDto;

  layersOpened: boolean = false;

  items: Array<DashboardItem> = [];

  private itemsLoaded = new Set<string>();

  private dashboardFullyLoaded = false;

  private itemRestorePoint: Array<DashboardItem> = [];

  constructor(private dashboardApi: DashboardApiService) {
    this.setupEditMode();
  }

  get dashboardItemChanged(): boolean {
    return !deepEquals(this.items, this.itemRestorePoint);
  }

  clearSelectedItem() {
    this.selectedItem$.next(null);
  }

  itemLoaded(item: DashboardItem) {
    if (this.dashboardFullyLoaded) return;
    this.itemsLoaded.add(item.id);
    // console.log('item loaded', item);
    // console.log('items', this.items);
    if (this.items.map((i) => this.itemsLoaded.has(i.id)).every((o) => o == true)) {
      this.dashboardFullyLoaded = true;
      (<any>window).dashboardLoaded = true;
      // console.log('dashboard fully loaded', this.items);
    }
  }

  resetLoadedItems() {
    this.dashboardFullyLoaded = false;
    this.itemsLoaded.clear();
    (<any>window).dashboardLoaded = false;
  }

  clearDashboard() {
    this.dashboard = null;
    this.items = [];
  }

  changedOptions() {
    this.options.api?.optionsChanged();
    this.setMaxLayer();
    this.optionsChanged$.next();
  }

  addItem(item: DashboardItem) {
    item.layerIndex = this.items.length;
    this.setAvailablePosition(item);
    item = JSON.parse(JSON.stringify(item)); //to remove basic obj properties
    ++this.options.maxLayerIndex;
    this.items.push(item);
    this.selectedItem$.next(item);
    this.changedOptions();
    // console.log('layer max index', this.options.maxLayerIndex);
  }

  deleteItem(item: DashboardItem) {
    const itemIndex = this.items.indexOf(item);
    this.items.splice(itemIndex, 1);
    this.setMaxLayer();
    this.fixLayers();
  }

  restoreDashboard(): Observable<void> {
    if (!this.dashboardItemChanged) return of(null);
    if (!this.itemRestorePoint) throw new Error('There is no saved restore point!');

    return new Observable<void>((observer) => {
      restoreDashboardItemArray(this.items, this.itemRestorePoint);
      if (this.items.find((o) => o.id === this.selectedItem$.value?.id) === null)
        this.selectedItem$.next(null);
      this.changedOptions();
      observer.next();
      observer.complete();
    });
  }

  fixLayers() {
    this.items.forEach((item, i) => (item.layerIndex = i));
  }

  setMaxLayer() {
    this.options.maxLayerIndex = this.items.length - 1;
  }

  sortLayers() {
    this.items.sort((a, b) => a.layerIndex - b.layerIndex);
  }

  saveDashboard(): Observable<boolean> {
    if (!this.dashboardItemChanged) return of(false);
    return this.dashboardApi.saveDashboard(this.getDashboardDto()).pipe(
      catchError(() => {
        // console.log('=>(dashboard.service.ts:125) e', e);
        return this.restoreDashboard().pipe(switchMap(() => of(false)));
        // return of(false);
      }),
    );
  }

  getDashboard(id: number): Observable<DashboardDto> {
    return this.dashboardApi.get(id);
  }

  setDashboard(dashboard: DashboardDto) {
    this.dashboard = dashboard;
    this.items = dashboard?.config ?? [];
    this.resetLoadedItems();
    this.saveRestorePoint();
    this.sortLayers();
    this.changedOptions();
  }

  setDashboards(result: DashboardDto[]) {
    this.dashboards = result;
    // this.dashboards.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
  }

  saveRestorePoint() {
    this.itemRestorePoint = JSON.parse(JSON.stringify(this.items));
  }

  directDownloadDashboard(id: number, lng: string, timezone: string, file_format: string) {
    return this.dashboardApi.directDownloadDashboard(id, lng, timezone, file_format);
  }

  private get gridsterConfig(): GridsterConfig {
    return {
      ...getDefaultGridsterConfig(),
      itemChangeCallback: () => this.triggerSettings$.next(false), //move settings if widget dropped on them
    };
  }

  private getDashboardDto(): DashboardUpdateDto {
    if (!this.dashboard) return null;
    this.sortLayers();
    return {
      id: this.dashboard.id,
      config: this.items,
    };
  }

  private setupEditMode() {
    this.editMode$.pipe(skip(1)).subscribe((value) => {
      this.options.draggable.enabled = this.options.resizable.enabled = value;
      this.options.displayGrid = value ? DisplayGrid.Always : DisplayGrid.None;
      this.setMaxLayer();
      this.clearSelectedItem();
      this.changedOptions();
      if (value == true) this.saveRestorePoint();
      else this.saveDashboard().subscribe();
    });
  }

  private setAvailablePosition(item: DashboardItem) {
    const availablePos = this.options.api.getFirstPossiblePosition(item);
    item.x = availablePos.x;
    item.y = availablePos.y;
  }
}
