import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { debounceTime, interval, Observable, Subject, takeUntil, takeWhile } from 'rxjs';
import { BaseWidgetComponent } from '../base-widget.component';
import { DashboardItemDataGraph } from '../../models/DashboardItemDataGraph.model';
import { DashboardService } from '../../services/dashboard.service';
import { NgIf } from '@angular/common';
import { DashboardItemDataGraphTypesModel } from '../../models/DashboardItemDataGraphTypes.model';
import { DashboardUplotGraphComponent } from './dashboard-uplot-graph/dashboard-uplot-graph.component';
import { DashboardPieChartComponent } from './dashboard-pie-chart/dashboard-pie-chart.component';
import { DashboardPieChartMonthlyAggComponent } from './dashboard-pie-chart/dashboard-pie-chart-monthly-agg.component';
import { DashboardPieChartWeeklyAggComponent } from './dashboard-pie-chart/dashboard-pie-chart-weekly-agg.component';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardHeatmapComponent } from './dashboard-heatmap/dashboard-heatmap.component';
import { DashboardHeatmapDailyComponent } from './dashboard-heatmap/dashboard-heatmap-daily.component';
import { DashboardSankeyComponent } from './dashboard-sankey/dashboard-sankey.component';

export type RefreshValue = 'series' | 'range' | 'graph' | void;

@Component({
  selector: 'app-dashboard-graph',
  templateUrl: './dashboard-graph.component.html',
  styleUrls: ['./dashboard-graph.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    DashboardUplotGraphComponent,
    DashboardPieChartComponent,
    DashboardPieChartMonthlyAggComponent,
    DashboardPieChartWeeklyAggComponent,
    TranslateModule,
    DashboardHeatmapComponent,
    DashboardHeatmapDailyComponent,
    DashboardSankeyComponent,
  ],
})
export class DashboardGraphComponent extends BaseWidgetComponent implements OnInit {
  protected override detach = false;

  private _refresh$: Subject<RefreshValue> = new Subject<RefreshValue>();

  refresh$: Observable<RefreshValue> = this._refresh$.pipe(debounceTime(100));

  constructor(
    protected override dashboardService: DashboardService,
    protected override cd: ChangeDetectorRef,
  ) {
    super(dashboardService, cd);
  }

  ngOnInit() {
    this.dashboardService.editMode$.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      if (value) this.watchData();
      else {
        this._refresh$.next(null);
      }
    });
  }

  override get data(): DashboardItemDataGraph {
    return this.item.data?.graph;
  }

  private watchData() {
    interval(300)
      .pipe(takeUntil(this.unsubscribe))
      .pipe(takeWhile(() => this.dashboardService.editMode$.value, true))
      .subscribe(() => {
        if (this.data?.seriesChanges) {
          this.data.seriesChanges = false;
          this._refresh$.next('series');
        }

        if (this.data?.refresh) {
          this.data.refresh = false;
          this._refresh$.next('graph');
          this.cd.detectChanges();
        }
      });
  }

  protected readonly DashboardItemDataGraphTypesModel = DashboardItemDataGraphTypesModel;
}
