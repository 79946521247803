import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { FormatValuePipe } from '../../../../pipes/format-value.pipe';
import { NgIf } from '@angular/common';
import { delay, filter, fromEvent, merge, Observable, of, take, takeUntil } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-heatmap-legend',
  standalone: true,
  imports: [FormatValuePipe, NgIf, MatTooltip],
  templateUrl: './heatmap-legend.component.html',
  styleUrl: './heatmap-legend.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeatmapLegendComponent implements AfterViewInit {
  @Input({ required: true }) uplot;

  @Input({ required: true }) heatmapPaletteColors: string[];

  @ViewChild('slider') slider: ElementRef;

  heatmapLegend: Array<{ color: string; min: number; max: number }>;

  paletteGradient: string;

  lastSelectedIndex: number;

  private unsubscribe = takeUntilDestroyed();

  private accuracy = 0.001;

  private handleSpace = 0.01;

  constructor(private cd: ChangeDetectorRef) {}

  get breakpoints(): Array<number> {
    return this.uplot?.heatmap?.breakpoints ?? [];
  }

  ngAfterViewInit() {
    this.uplot.seriesVisibiltyChanged$.pipe(delay(100), this.unsubscribe).subscribe(() => {
      this.updateLegend();
    });
  }

  private updateLegend() {
    const min = this.uplot?.heatmap?.min;
    const max = this.uplot?.heatmap?.max;
    if (min == null || max == null || this.breakpoints.length == 0) return null;

    const diff = max - min;
    const paletteVals: Array<{ color: string; min: number; max: number }> = [];
    this.heatmapPaletteColors.forEach((color: string, i: number) => {
      paletteVals.push({
        min: min + diff * this.breakpoints[i as number],
        max: i == this.heatmapPaletteColors.length - 1 ? max : min + diff * this.breakpoints[i + 1], // set max for last
        color: this.getBoxColor(i),
      });
    });
    this.createGradient();

    this.heatmapLegend = paletteVals;
    this.cd.detectChanges();
  }

  private createGradient() {
    this.paletteGradient = `linear-gradient(90deg,`;

    this.heatmapPaletteColors.forEach((color: string, i: number) => {
      const last = i == this.heatmapPaletteColors.length - 1;
      this.paletteGradient += `${color} ${this.breakpoints[i as number] * 100}% ${last ? '' : ','}`;
    });

    this.paletteGradient += `)`;
  }

  getBoxWidth(i: number): string {
    return ((this.breakpoints[i + 1] ?? 1) - this.breakpoints[i as number]) * 100 + '%';
  }

  getBoxColor(i: number): string {
    const firstColor = this.heatmapPaletteColors[i as number];
    const lastColor =
      i == this.heatmapPaletteColors.length - 1
        ? this.heatmapPaletteColors[i as number]
        : this.heatmapPaletteColors[i + 1];
    return `linear-gradient(90deg, ${firstColor} 0%, ${lastColor} 100%)`;
  }

  getBoxLeft(i: number): string {
    return this.breakpoints[i as number] * 100 + '%';
  }

  onSliderClick(event: MouseEvent) {
    const percentage = this.getPercentageFromEvent(event);

    const distanceMap = this.breakpoints.map((o) => Math.abs(o - percentage));

    //get the closest handler without first one (which should be always 0)
    const closestHandler = Math.max(distanceMap.indexOf(Math.min(...distanceMap)), 1);
    this.onHandleMouseDown(closestHandler, event);
  }

  onHandleMouseDown(index: number, _event: MouseEvent = null, _cancel: Observable<any> = null) {
    // console.log('=>(heatmap-legend.component.ts:114) index', index);
    if (index == 0) return;
    // console.log('=>(heatmap-legend.component.ts:135) index', index);
    this.lastSelectedIndex = index;
    const stop$ = _cancel ?? fromEvent(document, 'mouseup').pipe(take(1));

    merge(fromEvent(document, 'mousemove'), of(_event))
      .pipe(
        filter((e: MouseEvent) => !!e && e.buttons == 1),
        takeUntil(stop$),
        this.unsubscribe,
      )
      .subscribe({
        next: (event: MouseEvent) => {
          const moved = this.handleMove(index, this.getPercentageFromEvent(event));
          if (!moved) return;
          this.updateLegend();
          this.uplot.redraw();
          this.cd.detectChanges();
        },
        complete: () => {
          // console.log('completed');
        },
      });
  }

  private getPercentageFromEvent(event: MouseEvent): number {
    const containerX = this.slider.nativeElement.getBoundingClientRect().x;
    const containerWidth = this.slider.nativeElement.getBoundingClientRect().width;
    const mouseX = event.clientX;

    let percentage = (mouseX - containerX) / containerWidth;
    if (percentage > 1) percentage = 1;
    if (percentage < 0) percentage = 0;

    percentage = this.roundNumber(percentage);

    return percentage;
  }

  private roundNumber(number: number) {
    if (this.accuracy <= 0) throw Error('Invalid Accuracy');
    return Math.floor(number / this.accuracy) * this.accuracy;
  }

  private handleMove(index: number, percentage: number): boolean {
    let moved = true;

    if (percentage < 0 || percentage > 1) return true;
    //overlapping next handle
    if (
      this.breakpoints[index + 1] != null &&
      percentage + this.handleSpace > this.breakpoints[index + 1]
    ) {
      // console.log('recur 1', index);
      moved = this.handleMove(index + 1, percentage + this.handleSpace);
      percentage = this.breakpoints[index + 1] - this.handleSpace;
    }
    //overlapping previous handle
    if (
      this.breakpoints[index - 1] != null &&
      percentage - this.handleSpace < this.breakpoints[index - 1]
    ) {
      // console.log('recur 2', index);
      moved = this.handleMove(index - 1, percentage - this.handleSpace);
      percentage = this.breakpoints[index - 1] + this.handleSpace;
    }
    if (!moved) return false;

    //update current handle with proper value
    this.uplot.heatmap.breakpoints[index as number] = percentage;
    return true;
  }
}
