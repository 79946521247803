<!--<div class="range-settings">-->
<!--  <mat-select-->
<!--    [value]="currentScale$.value"-->
<!--    (selectionChange)="currentScale$.next($event.value)"-->
<!--    panelClass="mat-select-min-width-200"-->
<!--  >-->
<!--    <mat-option-->
<!--      *ngFor="let scaleType of scaleTypes"-->
<!--      [value]="scaleType"-->
<!--      [disabled]="!isScaleEnabled(scaleType)"-->
<!--    >{{ getScaleString(scaleType) | translate }}-->
<!--      <span-->
<!--        *ngIf="scaleType === UplotScaleModes.AUTO && currentScale$.value === UplotScaleModes.AUTO"-->
<!--      >({{ getScaleString(getRealCurrentScale()) | translate }})</span-->
<!--      >-->
<!--    </mat-option>-->
<!--  </mat-select>-->
<!--</div>-->

<app-emu-list-input
  [autocomplete]="false"
  [value]="value"
  [name]="'UPLOT.SCALE'"
  [displayWith]="displayFn"
  [filteredOptions$]="of(scaleTypes)"
  (optionSelected)="onSelected($event)"
  [clearButtonHidden]="true"
  [valueDisabledFn]="isScaleDisabled.bind(this)"
  [className]="className"
>
  <!--  [disabled]="true"-->

  <ng-template let-item>
    <p>
      {{ getScaleString(item) | translate }}
      <span
        *ngIf="item === UplotScaleModes.AUTO && value === UplotScaleModes.AUTO"
      >({{ getScaleString(getRealCurrentScale()) | translate }})</span
      >
    </p>
  </ng-template>

</app-emu-list-input>
