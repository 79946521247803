import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget.component';
import { BehaviorSubject } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { LocaleSessionService } from '../../../../shared/services/localeSession.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DashboardItemTypeMultipleValueDisplay } from '../../models/DashboardItemTypeMultipleValueDisplay';
import { DashboardValueDisplayComponent } from '../dashboard-value-display/dashboard-value-display.component';
import { DashboardItem } from '../../models/DashboardItem';
import { DashboardItemTypeValueDisplay } from '../../models/DashboardItemTypeValueDisplay';

@Component({
  selector: 'app-dashboard-multiple-value-display',
  templateUrl: './dashboard-multiple-value-display.component.html',
  styleUrls: ['./dashboard-multiple-value-display.component.scss'],
  standalone: true,
  imports: [MatProgressBarModule, TranslateModule, DashboardValueDisplayComponent],
})
export class DashboardMultipleValueDisplayComponent extends BaseWidgetComponent {
  protected override detach: boolean = false;

  constructor(
    public localeSession: LocaleSessionService,
    protected override dashboardService: DashboardService,
    protected override cd: ChangeDetectorRef,
  ) {
    super(dashboardService, cd);
  }

  loading = new BehaviorSubject(false);

  override get data(): DashboardItemTypeMultipleValueDisplay {
    return this.item?.data?.multipleValueDisplay ?? <DashboardItemTypeMultipleValueDisplay>{};
  }

  get editMode(): boolean {
    return this.dashboardService.editMode$.value;
  }

  getItemForValue(value: DashboardItemTypeValueDisplay, id: number): DashboardItem {
    return {
      ...this.item,
      id: `${this.item.id}-${id}`,

      data: { valueDisplay: value },
    };
  }

  isColumn() {
    return this.data.direction == 'column';
  }

  itemsLoaded: number[] = [];

  valueItemLoaded(index: number) {
    this.itemsLoaded.push(index);
    if (Object.keys(this.data.values).every((o) => this.itemsLoaded.includes(Number(o))))
      this.itemLoaded();
  }
}
