/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ItemTeeMetadata } from './itemTeeMetadata';
import { ItemTreeLngDto } from './itemTreeLngDto';

export interface FindOnTreeResponseDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * Item identification
   */
  id?: number;
  /**
   * Item icon
   */
  icon: string;
  /**
   * Parent device identifier
   */
  parent_id: number;
  /**
   * Item type identifier
   */
  item_type_id: number;
  /**
   * item_type_code
   */
  item_type_code?: FindOnTreeResponseDto.ItemTypeCodeEnum;
  /**
   * Item identifier
   */
  item_id: number;
  /**
   * Item identifier
   */
  readonly is_device?: number;
  /**
   * List captions in different languages
   */
  items: Array<ItemTreeLngDto>;
  /**
   * Additional item metadata
   */
  metadata?: Array<ItemTeeMetadata>;
  /**
   * item parents
   */
  parents?: Array<number>;
}
export namespace FindOnTreeResponseDto {
  export type ItemTypeCodeEnum = 'LOGGER' | 'LORA' | 'FOLDER' | 'DASHBOARD' | 'FILE' | 'IOT';
  export const ItemTypeCodeEnum = {
    LOGGER: 'LOGGER' as ItemTypeCodeEnum,
    LORA: 'LORA' as ItemTypeCodeEnum,
    FOLDER: 'FOLDER' as ItemTypeCodeEnum,
    DASHBOARD: 'DASHBOARD' as ItemTypeCodeEnum,
    FILE: 'FILE' as ItemTypeCodeEnum,
    IOT: 'IOT' as ItemTypeCodeEnum,
  };
}
