import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Host,
  Input,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { validateEmail } from '../emailValidator';
import { NumericOnlyDirective } from '../../../directives/numericOnly.directive';
import { PreventClickPropagationDirective } from '../../../directives/prevent-click-propagation.directive';

//Custom input component, handles [(value)] input and form controls
@Component({
  selector: 'app-emu-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    TranslateModule,
    NumericOnlyDirective,
    PreventClickPropagationDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmuInputComponent,
      multi: true,
    },
  ],
  templateUrl: './emu-input.component.html',
  styleUrl: './emu-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmuInputComponent implements AfterViewInit, ControlValueAccessor {
  private _value: string = '';

  get value(): string {
    return this._value;
  }

  @Input() set value(value: string) {
    this._value = value;
    this.updateClearButton();
  }

  //TODO validation error message

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input() formControlName: string;

  private control: AbstractControl;

  @Input() name: string;

  @Input() validator: (value: string) => boolean;

  @Input() placeholder: string;

  @Output() valueChange = new EventEmitter<string>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() paste = new EventEmitter<ClipboardEvent>();

  @Output() cleared = new EventEmitter<string>();

  @Output() changes = new EventEmitter<string>();

  @Output() valid = new EventEmitter<boolean>();

  @Input() className: string;

  isValid = true;

  showClearButton = false;

  @Input() disabled = false;

  @Input() readonly = false;

  @Input() forceClearButton = false;

  @Input() textArea = false;

  @Input() numeric = false;

  @Input() integer = false; // numeric not required if this used

  @Input() maxLength = 64;

  @Input() autocompleteTag: string;

  // Used when this component is not used in a formControl; emulates `touched` parameter from formControl
  protected focusedOut: boolean = false;

  private onChangeFormControl: Function;

  private onBlurFormControl: Function;

  @Input() set validateEmail(value: any) {
    if (coerceBooleanProperty(value) && this.validator == null) {
      this.validator = validateEmail;
    }
  }

  ngAfterViewInit() {
    this.updateClearButton();

    //form control
    if (this.controlContainer && this.formControlName)
      this.control = this.controlContainer.control.get(this.formControlName);

    // validate
    this.validate();
  }

  clear() {
    this.value = '';
    this.updateValue(this.value);
    this.cleared.emit();
    this.cdr.detectChanges();
  }

  updateValue(value: string) {
    this.valueChange.emit(value);
    this.changes.emit(value);
    if (this.onChangeFormControl) this.onChangeFormControl(value);
    this.updateClearButton();
    this.validate();
  }

  onPaste(event: ClipboardEvent) {
    this.paste.emit(event);
  }

  private updateClearButton() {
    this.showClearButton = this.value && this.value.length > 0;
    this.cdr.detectChanges();
  }

  validate() {
    const validatorValue = this.validator ? this.validator(this.value) : true;
    this.isValid = validatorValue && this.isFromControlValid();
    this.valid.emit(this.isValid);
    this.cdr.detectChanges();
  }

  onBlur() {
    if (this.onBlurFormControl && typeof this.onBlurFormControl == 'function')
      this.onBlurFormControl();
  }

  //form control functions
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChangeFormControl = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlurFormControl = fn;
  }

  writeValue(value: any): void {
    if (value && typeof value !== 'string') {
      console.log('value: ', value);
      throw new Error('THIS COMPONENT HANDLES STRING ONLY');
    }
    this.value = value;
    this.updateValue(value);
    // console.log('=>(emu-input.component.ts:174) obj', obj);
  }

  isFromControlValid() {
    if (!this.control) return true;
    return this.control.valid;
  }

  isTouched(): boolean {
    return this.control ? this.control.touched : this.focusedOut;
  }

  focusOut(): void {
    if (this.onBlurFormControl && typeof this.onBlurFormControl == 'function')
      this.onBlurFormControl();
    this.focusedOut = true;
  }

  //Form control handling:
  //https://angular.io/api/forms/ControlValueAccessor
  //https://stackoverflow.com/questions/44731894/get-access-to-formcontrol-from-the-custom-form-component-in-angular
}
