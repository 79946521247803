<ng-container *ngIf="displayPage">
  <!--  DISPLAY INVITATION MODAL ON QUERY-->
  <app-handle-query
    [watchedKeys]="['invitation']"
    (query)="onInvitation($event)"
  ></app-handle-query>
  <div class="main-layout">
    <app-header [showToggleSidenav]="(showTree$ | async) && mobileLayout" (toggleSidenav)="toggleSidenav()" class="app-header"></app-header>
    <mat-progress-bar *ngIf="isLoading() | async" mode="indeterminate"></mat-progress-bar>
      @if (showTree$ | async) {
        <!-- https://material.angular.io/components/sidenav/api#MatDrawerContainer -->
        <!-- autosize might cause performance issues, currently unnoticeable -->
        <mat-sidenav-container autosize [ngClass]="mobileLayout ? 'page-layout-mobile' : 'page-layout'">
          <mat-sidenav [disableClose]="!mobileLayout" [(opened)]="(layoutService.openedValue)" [mode]="mobileLayout ? 'over' : 'side'" class="side-bar">
            <app-main-layout-tree (nodeSelected)="closeSidenav()"></app-main-layout-tree>
          </mat-sidenav>
          <mat-sidenav-content class="page-content">
            <!--<app-page-links-tree></app-page-links-tree>-->
<!--            <div *ngIf="!smallLayout" class="header-padding"></div>-->
            <div class="router-wrapper">
              @if (displayRouter) {
                @if ((itemSelected$ | async) || !(requireSelectedItem$ | async)) {
                  <router-outlet></router-outlet>
                } @else {
                  <app-no-item-selected></app-no-item-selected>
                }
              }
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      } @else {
        <mat-sidenav-container class="page-layout">
          <mat-sidenav-content class="page-content">

<!--            <div *ngIf="!smallLayout" class="header-padding"></div>-->
            <div class="router-wrapper">
              @if (displayRouter) {
                <router-outlet></router-outlet>
              }
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      }
  </div>

  <app-footer></app-footer>
</ng-container>

<app-loading-circle [display]="!displayPage"></app-loading-circle>
