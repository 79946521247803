import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  isDevMode,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DashboardItem } from '../../../models/DashboardItem';
import { ConfirmDeleteDialogComponent } from '../../../../../shared/components/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { Subject, take } from 'rxjs';
import { DashboardItemType } from '../../../models/DashboardItemType.model';
import { positionDashboardItemBaseSettings } from '../../../utils/positionDashboardItemBaseSettings';
import { cloneItem } from '../../../utils/cloneItem';
import { isItemBlocked } from '../../../utils/isItemBlocked';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { DisabledDirective } from '../../../../../shared/directives/disabled.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, JsonPipe } from '@angular/common';

@Component({
  selector: 'app-item-base-settings',
  templateUrl: './item-base-settings.component.html',
  styleUrls: ['./item-base-settings.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    DisabledDirective,
    MatIconModule,
    JsonPipe,
    TranslateModule,
  ],
})
export class ItemBaseSettingsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container') container: ElementRef;

  DashboardItemType = DashboardItemType;

  private unsubscribe = new Subject<void>();

  constructor(
    private dashboardService: DashboardService,
    private dialog: MatDialog,
  ) {}

  ngAfterViewInit() {
    const mutation = new MutationObserver((mutations) =>
      positionDashboardItemBaseSettings(this.container, mutations),
    );
    mutation.observe(this.container.nativeElement, { subtree: true, attributes: true });
    this.unsubscribe.pipe(take(1)).subscribe(() => mutation.disconnect());
    setTimeout(() => positionDashboardItemBaseSettings(this.container), 50); // to position new element
  }

  get selectedItem(): DashboardItem {
    return this.dashboardService.selectedItem$.value;
  }

  get display() {
    return this.dashboardService.editMode$.value;
  }

  get displayInfo() {
    // @ts-ignore
    // showDevInfo for debugging on beta
    return isDevMode() || window.showDevInfo == true;
  }

  @HostListener('document:keydown.delete', ['$event'])
  removeItem(e = null) {
    if (e && document.activeElement.tagName == 'INPUT') return;
    if (isItemBlocked(this.selectedItem)) return;

    if (this.dialog.openDialogs.length > 0 || !this.selectedItem) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: 0,
    };
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    this.dialog
      .open(ConfirmDeleteDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        this.dashboardService.deleteItem(this.selectedItem);
        this.dashboardService.clearSelectedItem();
      });
  }

  cloneItem() {
    this.dashboardService.addItem(cloneItem(this.selectedItem));
  }

  toggleItemLock() {
    this.itemBlocked = !this.itemBlocked;
  }

  get itemBlocked(): boolean {
    return isItemBlocked(this.selectedItem);
  }

  set itemBlocked(value: boolean) {
    this.selectedItem.resizeEnabled = this.selectedItem.dragEnabled = !value;
    this.dashboardService.changedOptions();
    if (value == false) this.dashboardService.triggerSettings$.next(true);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
