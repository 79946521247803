import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMiniFabButton } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DataStructureTree } from '../../sankey-chart-config.component';
import { SeriesDataStructureDto } from '../../../../../../../../api-main';
import { fromEvent, Observable, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { EmuListInputComponent } from '../../../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { SankeyChartData } from '../../SankeyChartData.dto';

@Component({
  selector: 'app-sankey-chart-config-series',
  standalone: true,
  imports: [
    MatIcon,
    MatMiniFabButton,
    NgForOf,
    TranslateModule,
    MatTooltip,
    NgIf,
    CdkDrag,
    EmuListInputComponent,
  ],
  templateUrl: './sankey-chart-config-series.component.html',
  styleUrl: './sankey-chart-config-series.component.scss',
})
export class SankeyChartConfigSeriesComponent {
  constructor(
    private elRef: ElementRef,
    private dialog: MatDialog,
  ) {
    //close element if clicked outside (except when dialog is opened)
    const currentElement = this.elRef.nativeElement;
    fromEvent(document, 'mousedown')
      .pipe(takeUntilDestroyed())
      .subscribe((event) => {
        if (this.dialog.openDialogs.length > 0) return;

        let element: HTMLElement = <any>event.target;
        while (element.parentElement) {
          if (element == currentElement || element.classList.contains('cdk-overlay-container'))
            return;
          element = element.parentElement;
        }

        this.hideSeries.emit();
      });
  }

  @Input({ required: true }) item: DataStructureTree;

  getSeriesName(series: SeriesDataStructureDto): string {
    if (series.name && series.name.length > 0) return series.name;

    if (series.device_value_id == null && series.device_id == null) {
      if (series.logger_id) return `[l-${series.logger_id}]`;
      return null;
    }
    return `[l-${series.logger_id} d-${series.device_id} dv-${series.device_value_id}]`;
  }

  @Output() removeSeries = new EventEmitter<{
    item: DataStructureTree;
    series: SeriesDataStructureDto;
  }>();

  @Output() addSeries = new EventEmitter<DataStructureTree>();

  @Output() hideSeries = new EventEmitter<void>();

  @Output() editItem = new EventEmitter<DataStructureTree>();

  types$: Observable<SankeyChartData['type'][]> = of([
    'grid',
    'meter',
    'photovoltaic',
    'photovoltaic-export',
  ]);
}
