import { UplotData } from './models/UplotData.model';
import { UplotSeriesConfig } from './models/UplotSeriesConfig.model';
import { getUplotCursorOptions } from './config/uplotCursorOptions';
import { uplotGetSize } from './config/uplotDrawConfig';
import { uplotCreateLoadingCircle } from './utils/uplotLoadingCircle';
import { uplotMousePlugin } from './plugins/uplotPluginMouse';
import { uplotGetScales } from './config/uplotScales';
import { uplotGetSeries } from './config/uplotSeriesConfiguration';
import { uplotTooltipPlugin } from './plugins/uplotPluginTooltip';
import { uplotGetAxes } from './config/uplotAxes';
import { uplotMinMaxAvg } from './plugins/uplotMinMaxAvg';
import { uplotPluginResize } from './plugins/uplotPluginResize';
import { uplotUtilPlugin } from './plugins/uplotUtilPlugin';
import { touchZoomPlugin } from './plugins/uplotMobileTouch';
import { LocaleSessionService } from '../../../services/localeSession.service';
import { TranslateService } from '@ngx-translate/core';
import { YScaleEditHook } from './utils/YScaleEditHook';

export function uplotMakeChart(param: UplotMakeOpts) {
  // const container = this.container.nativeElement;
  const series = uplotGetSeries(param.seriesConfig, false, true);
  let opts = {
    ...uplotGetSize(param.container),
    tzDate: (ts) => uPlot.tzDate(new Date(ts * 1e3), param.localeService.timezone),
    title: param.title,
    cursor: getUplotCursorOptions(),
    scales: uplotGetScales(series),
    axes: uplotGetAxes(param.valueFormatFunction, param.dateFormatFunction, series),
    series: series,
    hooks: { ...YScaleEditHook },
    plugins: [
      // uplotTooltipPlugin(),
      uplotMousePlugin({}),
      uplotPluginResize(param.container),
      uplotMinMaxAvg(),
      uplotUtilPlugin(),
      touchZoomPlugin(),
      uplotTooltipPlugin({
        // formatFunction: param.valueFormatFunction,
        formatFunction: param.valueFormatFunction,
        localeService: param.localeService,
        translateService: param.translateService,
      }),
      // ...(param.isMobile ? [] : [uplotTooltipPlugin()]),
      ...param.plugins,
    ],
  };
  const u = new uPlot(opts, param.data, param.container);
  u.loadingElement = uplotCreateLoadingCircle(u.root, u);
  u.translate = param.translateService;
  u.multipleScales = true;
  // u.updateAxes = () => {
  //   u.axes = uplotGetAxes(param.valueFormatFunction, param.dateFormatFunction, u.series);
  //   console.log('uaxes:', u.axes);
  //   setScaleEdit(u);
  // };
  // u.updateAxes();
  return u;
}

export type UplotMakeOpts = {
  data: UplotData;
  container: HTMLElement;
  title: string;
  seriesConfig: UplotSeriesConfig[];
  plugins: Array<any>;
  isMobile?: boolean;
  valueFormatFunction: (number, any) => string;
  dateFormatFunction: (number, any) => string;
  localeService: LocaleSessionService;
  translateService: TranslateService;
};
