import { getAllBaseUnitsForSeries } from './getBaseUnitForScale';
import { uplotGetSize } from '../config/uplotDrawConfig';
import { debounce, fromEvent, of, Subject, takeUntil, timer } from 'rxjs';
import { CustomTooltip } from './CustomTooltip';
import { yScaleRange } from '../config/uplotScales';

function updateAxes(u) {
  const container = u.root.parentElement;
  if (!container) return;
  const axes = u.axes.filter((o) => o.scale != 'x').filter((o) => o.scale != 'y');

  axes.forEach((o) => o._el?.classList.toggle('u-off', !o.show));
  axes.forEach((o) => o._el?.classList.toggle('hide', !o.show));

  axes.filter((o) => o.show).forEach((o, i) => (o.side = 3 - (i % 2) * 2));

  const visibleAxes = axes.filter((o) => o.show);

  //as resizing is only option to recalculate axis position we need to fire it
  //however it does not work right after updating axes props so we need to
  //checking if they are correctly positioned and if not we fire resize and repeat the process
  //this usually work after second try so it shouldn't be an issue
  const check = () => {
    let wrongPositioning = false;
    // axesPos
    const leftAxes = visibleAxes.filter((o) => o.side == 3);
    const rightAxes = visibleAxes.filter((o) => o.side == 1);

    [leftAxes, rightAxes].forEach((axesToCheck) => {
      axesToCheck
        .sort((a, b) => a._pos - b._pos)
        .forEach((o, i, self) => {
          const pos = Math.floor(o._pos);
          const space = Math.floor(o._space + o._size);

          if (i == 0) {
            if (o.side == 3 && pos != space) wrongPositioning = true;
            return;
          }
          const prevAxePos = Math.floor(self[i - 1]._pos);
          if (prevAxePos != pos - space) wrongPositioning = true;
        });
    });

    const size = uplotGetSize(container);
    if (wrongPositioning) {
      toggleCoverGraph(u, true);
      u.setSize({ ...size, width: size.width + Math.random() });
      setTimeout(check, 500);
    } else {
      u.setSize({ ...size });
      toggleCoverGraph(u, false);
    }
  };

  check();
}

function hideAllUnusedScales(u) {
  // console.log('hideAllUnusedScales');
  const series = u.series;
  const baseUnits = getAllBaseUnitsForSeries(series);
  u.axes
    .filter((o) => o.scale != 'x')
    .filter((o) => o.scale != 'y')
    .filter((o) => !baseUnits.includes(o.scale))
    .forEach((o) => {
      o.show = false;
    });
  updateAxes(u);
}

function toggleCoverGraph(u, value: boolean) {
  if (!u.toggleGraphBuffor$) {
    u.toggleGraphBuffor$ = new Subject<any>();

    u.toggleGraphBuffor$
      .pipe(
        debounce((v) => (!v ? timer(200) : of(null))),
        takeUntil(u.destroy$),
      )
      .subscribe((v) => {
        const container = u.root;
        const className = 'loading';
        container.classList.toggle(className, v);
      });
  }
  u.toggleGraphBuffor$.next(value);
}

export const YScaleEditHook = {
  init: [
    (u) => {
      if (!u.destroy$) u.destroy$ = new Subject<void>();
      setScaleEdit(u);
      toggleCoverGraph(u, true);
    },
  ],
  addSeries: [
    (u, i) => {
      const series = u.series[i];
      const axis = u.axes.find((o) => o.scale == series.scale);
      if (axis) {
        axis.show = true;
      }
      updateAxes(u);
    },
  ],
  delSeries: [
    (u) => {
      hideAllUnusedScales(u);
    },
  ],
  drawAxes: [
    (u) => {
      if (u.axesDrawn) return;
      u.axesDrawn = true;
      hideAllUnusedScales(u);
    },
  ],
  //todo unsubscribe
  destroy: [
    (u) => {
      u.destroy$.next();
      u.destroy$.complete();
    },
  ],
};

export function setScaleEdit(u) {
  let axisEls = u.root.querySelectorAll('.u-axis');

  for (let i = 0; i < axisEls.length; i++) {
    if (i > 0) {
      let el = axisEls[i];

      const scaleKey = u.axes[i].scale;
      const scale = u.scales[scaleKey];

      const tooltipText = () => {
        if (u.translate) return u.translate.instant('UPLOT.YSCALE_TOOLTIP');
        else return null;
      };
      new CustomTooltip(el, tooltipText, u.destroy$);

      fromEvent(el, 'dblclick')
        .pipe(takeUntil(u.destroy$))
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        .subscribe((e: MouseEvent) => {
          if (e.buttons != 0) return;

          if (scale) scale.userSet = false;

          const seriesMinMax = u.series
            .filter((o) => o.scale == scaleKey)
            .map((o) => ({ min: o.min, max: o.max }))
            .reduce(
              (acc, curr) => {
                return { min: Math.min(acc.min, curr.min), max: Math.max(curr.max, curr.min) };
              },
              {
                min: Infinity,
                max: -Infinity,
              },
            );

          if (
            seriesMinMax.min == Infinity ||
            isNaN(seriesMinMax.min) ||
            seriesMinMax.max == -Infinity ||
            isNaN(seriesMinMax.max)
          )
            return;

          u.setScale(scaleKey, yScaleRange(u, seriesMinMax.min, seriesMinMax.max, scaleKey));
        });

      fromEvent(el, 'mousedown')
        .pipe(takeUntil(u.destroy$))
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        .subscribe((e: MouseEvent) => {
          const modBtnClicked = e.ctrlKey || e.buttons === 4;
          const y0 = e.clientY;

          const { min, max } = scale;
          const unitsPerPx = (max - min) / (u.bbox.height / uPlot.pxRatio);

          const mousemove = (mouseEvent) => {
            const dy = mouseEvent.clientY - y0;
            const shiftyBy = dy * unitsPerPx;

            const modValue = modBtnClicked ? shiftyBy : 0;
            const nMin = min + modValue;
            const nMax = max + shiftyBy - modValue;

            if (nMax < nMin) return;
            u.setScale(scaleKey, {
              min: nMin,
              max: nMax,
            });
            if (u.scales[scaleKey]) u.scales[scaleKey].userSet = true;
            // toggleScale(u, scaleKey);
          };

          let mouseup = () => {
            document.removeEventListener('mousemove', mousemove);
            document.removeEventListener('mouseup', mouseup);
          };

          document.addEventListener('mousemove', mousemove);
          document.addEventListener('mouseup', mouseup);
        });
    }
  }
}
