import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { BehaviorSubject, of } from 'rxjs';
import { DataRequestDto } from '../../../../../../api-main';
import { getScaleTypes } from '../utils/getScaleModes';
import { isIntervalAvailableForRange, uplotGetScaleMode } from '../config/uplotScales';
import { EmuListInputComponent } from '../../../inputs/emu-list-input/emu-list-input.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-scale-settings',
  standalone: true,
  imports: [NgIf, EmuListInputComponent, TranslateModule],
  templateUrl: './scale-settings.component.html',
  styleUrl: './scale-settings.component.scss',
})
export class ScaleSettingsComponent implements AfterViewInit {
  takeUntil = takeUntilDestroyed();

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.currentScale$)
      this.currentScale$.pipe(this.takeUntil).subscribe((val: DataRequestDto.ScaleEnum) => {
        this.value = val;
        this.cd.detectChanges();
      });

    if (!this.value) this.value = DataRequestDto.ScaleEnum.AUTO;
  }

  @Input() className: string = 'dashboard-settings smaller-height';

  @Input() currentScale$: BehaviorSubject<DataRequestDto.ScaleEnum>;

  @Input() value: DataRequestDto.ScaleEnum;

  @Output() valueChange = new EventEmitter<string>();

  @Input({ required: true }) rangeInHours: number;

  public scaleTypes = getScaleTypes();

  UplotScaleModes = DataRequestDto.ScaleEnum;

  isScaleDisabled(scale: DataRequestDto.ScaleEnum = null): boolean {
    return !isIntervalAvailableForRange(scale, this.rangeInHours);
  }

  getRealCurrentScale() {
    return uplotGetScaleMode(this.rangeInHours);
  }

  public getScaleString(scale: DataRequestDto.ScaleEnum): string {
    return `SCALE.${scale.toUpperCase()}`;
  }

  onSelected(scale: DataRequestDto.ScaleEnum) {
    this.valueChange.emit(scale);
    if (this.currentScale$) this.currentScale$.next(scale);
  }

  protected readonly of = of;

  displayFn(scale: DataRequestDto.ScaleEnum): string {
    return scale;
  }
}
