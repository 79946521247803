/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FilterItemDto } from './filterItemDto';

export interface ListFilterRequestDto {
  /**
   * List filtered fields with search values
   */
  filter?: Array<FilterItemDto>;
  /**
   * Sort direction
   */
  sort_direction: ListFilterRequestDto.SortDirectionEnum;
  /**
   * Fields to be sorted
   */
  sort?: Array<string>;
  /**
   * Page index
   */
  page_index: number;
  /**
   * Page offset
   */
  page_offset?: number;
  /**
   * Page size
   */
  page_size: number;
  /**
   * To determine access based on dashboard
   */
  dashboard_id?: number;
}
export namespace ListFilterRequestDto {
  export type SortDirectionEnum = 'asc' | 'desc' | '';
  export const SortDirectionEnum = {
    Asc: 'asc' as SortDirectionEnum,
    Desc: 'desc' as SortDirectionEnum,
    Empty: '' as SortDirectionEnum,
  };
}
