/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GpPrivilegesDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * Policy name
   */
  gp_name?: string;
  /**
   * Id of invitation
   */
  gp_id?: number;
  /**
   * Member e-mail
   */
  email: string;
  /**
   * User identifier.
   */
  user_id: string;
  /**
   * Date and time when this user accepted invitation (ISO_8601 format).
   */
  accepted_at?: string;
  /**
   * Id of invitation
   */
  invitation_id?: number;
  /**
   * Policy is valid from date. Could be null
   */
  dt_from: string;
  /**
   * Policy is valid to date. Could be null
   */
  dt_to: string;
  /**
   * Is invitation active
   */
  is_invitation_active?: boolean;
  /**
   * Is group policy active
   */
  is_gp_active?: boolean;
}
