import { debounceTime, fromEvent, startWith, Subject, take, takeUntil } from 'rxjs';

export class CustomTooltip {
  constructor(
    private el: HTMLElement,
    private text: string | (() => string),
    private destroy$: Subject<void>,
  ) {
    this.init();
    this.destroy$.pipe(take(1)).subscribe(() => this.destroy());
  }

  private mouseEnter$ = fromEvent(this.el, 'mouseenter');

  private mouseLeave$ = fromEvent(this.el, 'mouseleave');

  private mouseMove$ = fromEvent(this.el, 'mousemove');

  private tooltip = this.createTooltip();

  private showDelay = 1e3;

  private tooltipDisplayed = false;

  private init() {
    this.mouseEnter$.pipe(takeUntil(this.destroy$)).subscribe((e: MouseEvent) => {
      this.mouseEnter$
        .pipe(
          startWith(e),
          debounceTime(this.showDelay),
          take(1),
          takeUntil(this.mouseLeave$),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          if (!this.tooltipDisplayed) this.showTooltip(e);
        });
    });

    this.mouseLeave$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.hideTooltip();
    });
  }

  private createTooltip() {
    const tooltip = document.createElement('div');

    tooltip.classList.add('custom-tooltip');
    this.updateText(tooltip);

    return tooltip;
  }

  private updateText(tooltip = this.tooltip) {
    if (typeof this.text == 'function') tooltip.innerText = this.text();
    else tooltip.innerText = this.text;
  }

  private showTooltip(e: MouseEvent) {
    if (this.tooltipDisplayed) {
      return;
    }
    this.updateText();
    this.tooltipDisplayed = true;
    document.body.append(this.tooltip);

    const offset = 10;
    this.mouseMove$
      .pipe(startWith(e), takeUntil(this.mouseLeave$), takeUntil(this.destroy$))
      .subscribe((moveE: MouseEvent) => {
        this.tooltip.style.left = moveE.pageX + offset + 'px';
        this.tooltip.style.top = moveE.pageY + offset + 'px';
      });
  }

  hideTooltip() {
    if (!this.tooltipDisplayed) return;
    try {
      document.body.removeChild(this.tooltip);
    } catch (e) {
      console.warn(e);
    }
    this.tooltipDisplayed = false;
  }

  private destroy() {
    this.tooltip = null;
  }
}
