import { DataDisplayDefaultRanges } from '../../../../models/DataDisplayDefaultRanges';

let definedRanges = new Set<DataDisplayDefaultRanges>();
export function getDefinedRanges(custom = true): DataDisplayDefaultRanges[] {
  // console.log('Defined ranges: ', JSON.parse(JSON.stringify(definedRanges)));
  if (definedRanges.size === 0) setDefinedRanges();
  const definedRangesArray = Array.from(definedRanges);
  if (!custom) return definedRangesArray.filter((o) => o !== DataDisplayDefaultRanges.CUSTOM);
  else return definedRangesArray;
}

function setDefinedRanges() {
  for (let enumMember in DataDisplayDefaultRanges) {
    // console.log('=>(getDefinedRanges.ts:14) enumMember', JSON.parse(JSON.stringify(enumMember)));
    definedRanges.add(<DataDisplayDefaultRanges>enumMember);
    // const isValueProperty = Number(enumMember) >= 0;
    // if (isValueProperty) {
    //   definedRanges.push(Number(enumMember));
    // }
  }
}
