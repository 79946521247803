import { Component, inject, OnDestroy } from '@angular/core';
import { filter, map, Observable, merge, Subject, of } from 'rxjs';
import { LayoutService } from '../layout.service';
import { WaitService } from '../../shared/services/wait.service';
import { InvitationsComponent } from '../invitations/invitations.component';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegesService } from '../../shared/services/privileges.service';
import {
  HandleQueryEvent,
  HandleQueryComponent,
} from '../../shared/components/handle-query/handle-query.component';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LoadingCircleComponent } from '../loading-circle/loading-circle.component';
import { FooterComponent } from '../footer/footer.component';
import { NoItemSelectedComponent } from './no-item-selected/no-item-selected.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MainLayoutTreeComponent } from './main-layout-tree/main-layout-tree.component';
import { HeaderComponent } from './header/header.component';
import { NgIf, AsyncPipe, NgClass } from '@angular/common';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import breakpoints, { smallBreakpoints } from '../../shared/utils/breakpoints';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    HandleQueryComponent,
    HeaderComponent,
    MainLayoutTreeComponent,
    MatProgressBarModule,
    RouterOutlet,
    NoItemSelectedComponent,
    FooterComponent,
    LoadingCircleComponent,
    AsyncPipe,
    MatSidenavContainer,
    MatSidenavContent,
    MatSidenav,
    NgClass,
  ],
})
export class MainLayoutComponent implements OnDestroy {
  protected mobileLayout: boolean;

  protected smallLayout: boolean;

  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private waitService: WaitService,
    private dialog: MatDialog,
    private privilegesService: PrivilegesService,
  ) {
    inject(BreakpointObserver)
      .observe(smallBreakpoints)
      .pipe(takeUntilDestroyed())
      .subscribe((breakpoint) => {
        this.smallLayout = breakpoint.matches;
      });
    inject(BreakpointObserver)
      .observe(breakpoints)
      .pipe(takeUntilDestroyed())
      .subscribe((breakpoint) => {
        this.mobileLayout = breakpoint.matches;
        if (!this.mobileLayout) this.layoutService.openSidenav();
      });
  }

  itemSelected$ = this.layoutService.selectedItem$.pipe();

  get displayRouter() {
    return this.layoutService.organizationLoaded() && this.layoutService.routeReload();
  }

  get displayPage(): boolean {
    return this.layoutService.languageSet;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  isLoading() {
    return this.waitService.isLoading$;
  }

  onInvitation(event: HandleQueryEvent) {
    this.dialog.open(InvitationsComponent, { disableClose: true, data: { code: event.value } });
  }

  $routeData: Observable<null | Data> = merge(
    this.layoutService.organizationChanged$,
    of(null),
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
  ).pipe(
    map(() => {
      let child = this.route.firstChild;
      while (child) {
        if (child.firstChild) child = child.firstChild;
        else if (child.snapshot?.data) {
          return child.snapshot.data;
        } else return null;
      }
      return null;
    }),
  );

  requireSelectedItem$ = this.$routeData.pipe(
    map((data: any) => {
      if (!this.privilegesService.organization()) return false;
      if (!data) return true;
      return data?.selectedItemRequired !== false;
    }),
  );

  showTree$ = this.$routeData.pipe(
    map((data: any) => {
      if (!this.privilegesService.organization()) return false;
      if (!data) return true;
      return data?.treeDisplay !== false;
    }),
  );

  toggleSidenav() {
    if (!this.mobileLayout) return;
    this.layoutService.toggleSidenav();
  }

  closeSidenav() {
    if (!this.mobileLayout) return;
    this.layoutService.closeSidenav();
  }
}
