import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TreeNodeService } from '../TreeNodeService';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TreeFilterModalComponent } from './tree-filter-modal/tree-filter-modal.component';
import { TreeNodeRefreshOptions } from '../models/TreeNodeRefreshOptions';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleExpandComponent } from './toggle-expand/toggle-expand.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TreeSearchComponent } from './tree-search/tree-search.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import breakpoints from '../../../../../shared/utils/breakpoints';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-tree-header-buttons',
  templateUrl: './tree-header-buttons.component.html',
  styleUrls: ['./tree-header-buttons.component.scss'],
  standalone: true,
  imports: [
    TreeSearchComponent,
    MatTooltipModule,
    MatIconModule,
    ToggleExpandComponent,
    TranslateModule,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class TreeHeaderButtonsComponent implements OnInit, OnDestroy {
  protected mobileLayout: boolean;

  @Input() treeNodeService: TreeNodeService;

  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) {}

  private unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.breakpointObserver
      .observe(breakpoints)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (breakpoint) => {
          this.mobileLayout = breakpoint.matches;
          this.cd.detectChanges();
        },
      });
  }

  openFilter() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      treeNodeService: this.treeNodeService,
    };
    this.dialog
      .open(TreeFilterModalComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW));
  }

  refresh() {
    this.treeNodeService.refresh(TreeNodeRefreshOptions.ALL);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
