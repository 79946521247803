<mat-card class="dashboard-container" [class.public]="isPublic">
  <mat-card-header class="header" *ngIf="showHeader&&!isPublic">
    <app-dashboard-header></app-dashboard-header>
  </mat-card-header>

  <mat-card-header *ngIf="isPublic&& (organization$ | async) as org" class="public-header">
    <div class="logo-container">
      <img *ngIf="org.company_logo" class="logo" [src]="org.company_logo" alt=" "/>
      <span>{{org.company_name}}</span>
    </div>
  </mat-card-header>

  <app-item-settings></app-item-settings>

  <mat-card-content *ngIf="(!isPublic || (dashboard)); else skeleton">
    <div *ngIf="noItemsMessage" class="no-items">
      <img ngSrc="assets-common/images/dashboard_preview.svg" fill="" />
      <div class="info-container">
        <p>
          <mat-icon svgIcon="info"></mat-icon>
          <span class="info"> {{ 'NOTIFICATION.INFO' | translate }}</span>
        </p>
        <span class="message">{{ 'DASHBOARD.EMPTY_DASHBOARD_MESSAGE' | translate }}</span>
      </div>
    </div>
    <gridster [options]="options">
      <app-dashboard-item [item]="item" *ngFor="let item of dashboard"></app-dashboard-item>
    </gridster>
  </mat-card-content>

  <mat-card-footer *ngIf="isPublic" class="public-footer">
    <app-date-display></app-date-display>
  </mat-card-footer>

</mat-card>

<ng-template #skeleton>
  <app-skeleton></app-skeleton>
</ng-template>
