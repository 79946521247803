/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SingleDataResponseDto {
  /**
   * request id
   */
  id?: string;
  /**
   * Logger identifier
   */
  logger_id: number;
  /**
   * Value data identifier
   */
  value_id: number;
  /**
   * Date
   */
  time: Date;
  /**
   * Value
   */
  value: number;
  /**
   * Value
   */
  cfg_unit: number;
  /**
   * unit
   */
  unit: string;
  /**
   * description
   */
  description: string;
  /**
   * start
   */
  start: number;
  /**
   * end
   */
  end: number;
}
