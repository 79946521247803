<div class="select-container">
  <app-emu-list-input
    [loading$]="loading$"

    [value]="selectedDeviceValue"
    (valueChange)="deviceValueInput$.next($event)"
    [name]="'LOGGER.DEVICE_VALUES'"
    (optionSelected)="selectedDeviceValue = $event;valueDisplaySelected()"
    [displayWith]="getDeviceParameterName.bind(this)"
    [filteredOptions$]="deviceValues$"
    (optionCleared)="resetValues()"
    [className]="'dashboard-settings no-wrap'"
    [disabled]="deviceValues.length === 0 || !isDataAvailable()"

  >

    <ng-template let-item>
      <p class="device-name ">{{ getDeviceParameterName(item) }}</p>

      <p class="last-connection">
        {{ 'LOGGER.LAST_CONNECTION' | translate }}:
        <app-timestamp-relative
          [time]="item.last_import"
          [showRelative]="true"
        ></app-timestamp-relative>
        ( {{ item.last_value }} [{{ item.unit_str }}])
      </p>
<!--      <p class="last-connection value">-->

<!--      </p>-->
      <p class="last-connection value" *ngIf="item.cfg_obis_code	">
         {{ 'LOGGER.OBIS_CODE' | translate }}:  {{ item.cfg_obis_code }}
      </p>
    </ng-template>

  </app-emu-list-input>
</div>
