<app-graph
  #graphComponent
  [data]="getData"
  [uniqueId]="item.id.toString()"
  [seriesConfig$]="seriesConfig$"
  [showRange]="data.showRange ?? true"
  [showSettings]="false"
  [defaultRange]="defaultRange"
  [defaultScale]="defaultScale"
  [showCustomRangesSettings]="false"
  [showScaleSettings]="true"
  (graphLoaded)="itemLoaded()"
  [customDefaultRange]="data.range"
  [currentScale$]="currentScale$"
>
  <span class="name">{{ data.name ?? ('DASHBOARD.TYPE_GRAPH' | translate) }}</span>
</app-graph>
