import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatButtonModule, NgStyle],
})
export class DropdownComponent implements AfterViewInit {
  protected middle: string;

  @ViewChild('dropdownContent') content: ElementRef;

  @Input() position: 'left' | 'right' | 'middle' = 'right';

  @Input() customStyles = {};

  ngAfterViewInit() {
    // console.log(this.content);
    // console.log(this.content.nativeElement);
    this.middle = -this.content.nativeElement.offsetWidth / 2 + 'px';
  }
}
