<app-modal
  [title]="'ORGANIZATION.SELECT_ORGANIZATION' | translate"
  [isDataLoaded]="true"
  [saveButtonName]="getSaveButtonName()"
  [cancelButtonName]="getCancelButtonName()"
  (save)="openAddDialog()"
  (cancel)="selectNoneOrganization()"
  customCancelBehavior
>
  <ng-container body>
    <div class="list-container">
      @if (waitService.isLoading()) {
        <app-skeleton></app-skeleton>
      } @else {
        @for (organization of organizations; track organization) {
          <div class="organization" (click)="organizationSelected(organization)">
            <img *ngIf="organization.company_logo; else defaultIcon" [src]="organization.company_logo" alt=""/>
            <div class="organization-info">
              <span>{{organization.company_name}}</span>
              <div class="organization-info-minor">
                <span>{{organization.vat_number}} | </span>
                <span>{{organization.role}}</span>
              </div>
            </div>
          </div>
        }
      }
    </div>
  </ng-container>
</app-modal>

<ng-template #defaultIcon>
  <div class="logo">
    <mat-icon>corporate_fare</mat-icon>
  </div>
</ng-template>
