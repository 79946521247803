/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ItemTreeListItemDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * Item identification
   */
  id: number;
  /**
   * Item icon
   */
  icon: string;
  /**
   * Parent device identifier
   */
  parent_id: number;
  /**
   * Device name
   */
  item_name: string;
  /**
   * Item type identifier
   */
  item_type_id: number;
  /**
   * Item identifier
   */
  item_id: number;
  /**
   * Item type description
   */
  item_type: string;
  /**
   * False if item_tree is connected with item and user doesnt have access to that item
   */
  is_authorized: boolean;
  /**
   * If response is for root
   */
  root_item_response: boolean;
  /**
   * Role which user has on this item
   */
  role: ItemTreeListItemDto.RoleEnum;
  /**
   * Number of children
   */
  children_count: number;
  /**
   * Wheter item is end type
   */
  is_end_type: boolean;
}
export namespace ItemTreeListItemDto {
  export type RoleEnum = 'Edit' | 'View';
  export const RoleEnum = {
    Edit: 'Edit' as RoleEnum,
    View: 'View' as RoleEnum,
  };
}
