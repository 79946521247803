import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { LocaleSessionService } from '../../services/localeSession.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getRelativeDateString } from './getRelativeDateString';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { DateDisplayComponent } from '../date-display/date-display.component';

@Component({
  selector: 'app-timestamp-relative',
  templateUrl: './timestamp-relative.component.html',
  styleUrls: ['./timestamp-relative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatTooltipModule, DateDisplayComponent],
})
export class TimestampRelativeComponent {
  get time(): any {
    return this._time;
  }

  @Input() set time(value: any) {
    this._time = value;
    this.init();
  }

  private _time = null;

  @Input() showRelative = false;

  relativeTime = null;

  get timeString(): string {
    if (!this.time) return null;
    // return this.time.toLocaleString(this.localeSession.locale, { timeZone: 'UTC' }) + ' (UTC)';
    return (
      new Intl.DateTimeFormat(this.localeSession.locale, {
        dateStyle: 'short',
        timeStyle: 'medium',
        timeZone: 'UTC',
      }).format(new Date(this.time)) + ' (UTC)'
    );
  }

  constructor(
    public localeSession: LocaleSessionService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    timer(0, 1000)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.init();
      });
  }

  private init() {
    if (!this.time) return;
    const last = new Date(this.time);
    this.relativeTime = getRelativeDateString(last, this.translate);

    this.cd.markForCheck();
  }
}
