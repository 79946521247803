import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget.component';
import { DashboardItemDataImage } from '../../models/DashboardItemDataImage.model';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-dashboard-image',
  templateUrl: './dashboard-image.component.html',
  styleUrls: ['./dashboard-image.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class DashboardImageComponent extends BaseWidgetComponent {
  override get data(): DashboardItemDataImage {
    return this.item.data?.image;
  }

  onImageLoaded() {
    this.itemLoaded();
  }
}
