import { AfterViewInit, Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { UplotRangePickerComponent } from '../../../../../shared/components/graph-uplot/uplot-range-picker/uplot-range-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardHeatmapComponent } from './dashboard-heatmap.component';
import { HeatMapDailyComponent } from '../../../../../shared/components/graph-uplot/heat-map/daily/heat-map-daily.component';

@Component({
  selector: 'app-dashboard-heatmap-daily',
  standalone: true,
  imports: [NgIf, UplotRangePickerComponent, TranslateModule, HeatMapDailyComponent],
  templateUrl: './dashboard-heatmap-daily.component.html',
  styleUrl: './dashboard-heatmap.component.scss',
})
export class DashboardHeatmapDailyComponent
  extends DashboardHeatmapComponent
  implements AfterViewInit {}
