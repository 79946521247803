import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GeneralService } from '../../../api-main';
import { ConfigService } from '../../shared/services/config.service';
import { CookieConsentBannerComponent } from '../../shared/components/cookie-constent-banner/cookie-consent-banner.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [CookieConsentBannerComponent],
})
export class FooterComponent implements OnInit, OnDestroy {
  version: string = 'loading..';

  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private generalService: GeneralService,
  ) {
    this.generalService = new GeneralService(this.http, ConfigService.getApiConfig(), undefined);
  }

  ngOnInit(): void {
    this.generalService
      .generalControllerGetVersion()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (resp) => {
          this.version = resp.app_version;
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
