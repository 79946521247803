import { UplotData } from '../models/UplotData.model';
import { getScaleValueInSeconds } from '../config/uplotScales';
import { UplotSeriesConfig } from '../models/UplotSeriesConfig.model';
import { SeriesCalculationMode } from '../models/SeriesCalculationMode';
import { isSeriesConsumptionValue } from './isSeriesConsumptionValue';
import { DataRequestDto } from '../../../../../../api-main';

export interface FormatDataToScale {
  data: UplotData;
  scale: DataRequestDto.ScaleEnum;
  series: UplotSeriesConfig[];
  // desiredScale: UplotScaleModes;
}

export function formatDataToScale(opts: FormatDataToScale): UplotData {
  opts.data = fillGap(opts.data);

  const newDates = formatDates(opts.data[0], opts.scale);

  const formattedData = formatData(opts, newDates);

  return formattedData;
}

export function getScaleForApi(scale: DataRequestDto.ScaleEnum) {
  if (getScaleValueInSeconds(scale) < getScaleValueInSeconds(DataRequestDto.ScaleEnum.DAILY))
    return DataRequestDto.ScaleEnum.FIFTEENMINUTES;
  else return DataRequestDto.ScaleEnum.DAILY;
}

function fillGap(_data: UplotData) {
  const data: UplotData = JSON.parse(JSON.stringify(_data));
  const dataX = data[0];
  const smallestDiff = Math.min(
    ...new Set(dataX.map((o, i, self) => (self[i + 1] ? self[i + 1] - o : null)).filter((o) => o)),
  );
  // we can't leave smallestDiff because time change can lead to diff=3600
  const intervalDiff = smallestDiff; // > 900 ? 86400 : 900;

  for (let i = 0; i < dataX.length - 1; i++) {
    const diff = dataX[i + 1] - dataX[i];
    const missingIntervals = diff / intervalDiff - 1;
    if (missingIntervals <= 0) continue;

    for (let j = 1; j <= missingIntervals; j++) {
      dataX.splice(i, 0, dataX[i] + intervalDiff * j);
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      data.slice(1).forEach((d: number[]) => {
        d.splice(i, 0, null);
      });
      i++;
    }
  }
  return data;
}

function formatData(opts: FormatDataToScale, desiredRanges: number[]): UplotData {
  const data = opts.data;
  const formattedData = [desiredRanges];

  if (data[0].length == desiredRanges.length) return opts.data;
  data.slice(1).forEach(() => formattedData.push([]));

  let lastXDataIndex = 0;
  for (let xi = 0; xi < desiredRanges.length; xi++) {
    const xData = desiredRanges[xi];
    const xDataNext = xi < desiredRanges.length - 1 ? desiredRanges[xi + 1] : null;

    //Get indexes of data which are added to this timestamp

    const xDataIndexes = [];
    for (let i = lastXDataIndex; i < data[0].length; i++) {
      const date = data[0][i];
      if (date >= xData && (xDataNext === null || date < xDataNext)) xDataIndexes.push(i);
    }
    lastXDataIndex = xDataIndexes.slice(-1)[0] ?? lastXDataIndex; // future indexes will be bigger than last here

    //sum up previously calculated indexes for each series;
    for (let si = 1; si < data.length; si++) {
      let values = [];

      xDataIndexes.forEach((i) => {
        const val = data[si][i];
        if (val) values.push(val);
      });
      // worse performance

      const valuesSum = values.reduce((a, b) => a + b, 0);
      const valuesCount = values.filter((o) => o != null).length;

      //If value is consumption show sum if not show avg
      //TODO to decide if we show avg or last/first value for this series
      const series = opts.series[si - 1];
      let value: number;

      if (values.every((o) => o == null)) {
        formattedData[si].push(null);
        continue;
      }

      if (isSeriesConsumptionValue(series)) {
        if (series.mode == SeriesCalculationMode.CONSUMPTION_CALC || series.mode == undefined)
          value = valuesSum;
        else value = values[0]; //value is meter reading
      } else {
        value = valuesSum / Math.max(valuesCount, 1);
      }
      if (isNaN(value)) value = null;
      formattedData[si].push(value);
    }
  }

  return formattedData;
}

function formatDates(dates: number[], scale: DataRequestDto.ScaleEnum) {
  let formattedDates = dates.map((date) => {
    const d = new Date(date * 1000);
    switch (scale) {
      case DataRequestDto.ScaleEnum.YEARLY:
        d.setHours(0, 0, 0, 0);
        d.setMonth(0, 1);
        break;
      case DataRequestDto.ScaleEnum.HALFYEARLY:
        const yearHalf = Math.floor(d.getMonth() / 6);
        d.setHours(0, 0, 0, 0);
        d.setDate(1);
        d.setMonth(yearHalf * 6);
        break;
      case DataRequestDto.ScaleEnum.QUARTER:
        const quarter = Math.floor(d.getMonth() / 3);
        d.setHours(0, 0, 0, 0);
        d.setDate(1);
        d.setMonth(quarter * 3);
        break;
      case DataRequestDto.ScaleEnum.MONTHLY:
        d.setHours(0, 0, 0, 0);
        d.setDate(1);
        break;
      case DataRequestDto.ScaleEnum.WEEKLY:
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() - d.getDay() + 1);
        break;
      case DataRequestDto.ScaleEnum.DAILY:
        d.setHours(0, 0, 0, 0);
        break;
      case DataRequestDto.ScaleEnum.HOURLY:
        d.setUTCMinutes(0, 0, 0);
      // default:
    }
    return d.getTime() / 1000;
  });

  formattedDates = Array.from(new Set(formattedDates));
  formattedDates.sort((a, b) => a - b);

  return formattedDates;
}
