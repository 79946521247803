<main>
  <section>
    <app-settings-logger [data]="data" [reloadDevice$]="reloadDevice$" (valueSelected)="onLoggerSelected()"> </app-settings-logger>
  </section>
  <section *ngIf="showDevices()">
    <app-settings-device
      [data]="data"
      [reloadDevice$]="reloadDevice$"
      [reloadDeviceValues$]="reloadDeviceValues$"
    ></app-settings-device>
  </section>

  <section *ngIf="showDeviceValues()">
    <app-settings-value-data
      [data]="data"
      [reloadDeviceValues$]="reloadDeviceValues$"
      (valueSelected)="valueDisplaySelected()"
    ></app-settings-value-data>
  </section>

  <section *ngIf="data && canUseConsumption() && showConsumption" class="consumption">
    <app-emu-list-input
      [(value)]="data['consumption']"
      (optionSelected)="valueDisplaySelected()"
      [name]="'DASHBOARD.VALUE_CALC_MODE'"
      [autocomplete]="false"
      [valueFnc]="consumptionOptionsValueFn.bind(this)"
      [clearButtonHidden]="true"
      [displayWith]="consumptionOptionsDisplayFn.bind(this)"
      [filteredOptions$]="consumptionOptions"
      [className]="'dashboard-settings '"
    >
      <ng-template let-item
        ><p>{{ item.name | translate }}</p></ng-template
      >
    </app-emu-list-input>

    <!--    <mat-form-field appearance="fill">-->
    <!--      <mat-label>{{'DASHBOARD.VALUE_CALC_MODE' | translate}}</mat-label>-->
    <!--      <mat-select [(ngModel)]="data['consumption']" (ngModelChange)="valueDisplaySelected()">-->
    <!--        <mat-option [value]="false">{{'GRAPH.METER_READING' | translate}}</mat-option>-->
    <!--        <mat-option [value]="true">{{'GRAPH.CONSUMPTION' | translate}}</mat-option>-->
    <!--      </mat-select>-->
    <!--    </mat-form-field>-->
  </section>
</main>
