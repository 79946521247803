import { obisCodeCfgUnits } from '../models/ObisCodeCfgUnits';
import { cfgUnits } from '../models/CfgUnits';
import { UplotSeriesConfig } from '../models/UplotSeriesConfig.model';

export function getBaseUnitForScale(unit: string): string {
  // obisCodeCfgUnits
  const obisGroup = obisCodeCfgUnits.find((o) => o.units.includes(unit));
  if (obisGroup && obisGroup.baseUnit) return obisGroup.baseUnit;
  return 'y';
}

export function getAllBaseUnitsForSeries(series: UplotSeriesConfig[] = null) {
  const allUnits = Array.from(cfgUnits.values()).map((o) => getBaseUnitForScale(o.unit));
  const additionalUnits = ['None'];
  const excludeUnits = ['y'];

  return [...allUnits, ...additionalUnits]
    .filter((o, i, s) => s.findIndex((fi) => fi == o) == i)
    .filter((o) => {
      if (!series) return true;
      else return series.map((s: any) => s.scale).includes(o);
    })
    .filter((o) => !excludeUnits.includes(o));
}
