<div class="select-container">

  <!--  [displayWith]="displayLoggerFn.bind(this)"-->
  <app-emu-list-input
    [loading$]="loading$"

    [value]="selectedDevice"
    (valueChange)="deviceInput$.next($event)"
    [name]="'LOGGER.DEVICES'"
    (optionSelected)="selectedDevice = $event"
    [displayWith]="displayDeviceFn.bind(this)"
    [filteredOptions$]="devices$"
    (optionCleared)="resetValues()"
    [className]="'dashboard-settings no-wrap'"
    [disabled]="devices.length === 0 || !data.loggerId"
    [readonly]="readonly"
  >
    <ng-template let-item>
      <p class="device-name no-wrap no-wrap">{{ item.name }}</p>
    </ng-template>

    <!--    <ng-template let-item #displayedValue>-->
    <!--      <p class="device-name no-wrap no-wrap">{{ item?.name }}</p>-->
    <!--    </ng-template>-->
  </app-emu-list-input>
</div>
