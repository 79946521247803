/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MetadataSaveDto {
  /**
   * id
   */
  id: number;
  /**
   * entity_type
   */
  entity_type: MetadataSaveDto.EntityTypeEnum;
  /**
   * key
   */
  key: string;
  /**
   * value
   */
  value: string;
  /**
   * entity_id
   */
  entity_id: number;
  /**
   * If entity_id is null we can try to find this information based on logger_id
   */
  logger_id: number;
  /**
   * Organization id
   */
  organization_id: number;
  /**
   * type
   */
  type: MetadataSaveDto.TypeEnum;
  /**
   * from
   */
  from: string;
  /**
   * to
   */
  to: string;
}
export namespace MetadataSaveDto {
  export type EntityTypeEnum = 'LOGGER' | 'DEVICE' | 'DEVICE_VALUE' | 'TREE_NODE';
  export const EntityTypeEnum = {
    LOGGER: 'LOGGER' as EntityTypeEnum,
    DEVICE: 'DEVICE' as EntityTypeEnum,
    DEVICEVALUE: 'DEVICE_VALUE' as EntityTypeEnum,
    TREENODE: 'TREE_NODE' as EntityTypeEnum,
  };
  export type TypeEnum =
    | 'string'
    | 'location'
    | 'number'
    | 'date'
    | 'date_range'
    | 'tariff'
    | 'boolean'
    | 'config_iot'
    | 'file'
    | 'image'
    | 'pricing';
  export const TypeEnum = {
    String: 'string' as TypeEnum,
    Location: 'location' as TypeEnum,
    Number: 'number' as TypeEnum,
    Date: 'date' as TypeEnum,
    DateRange: 'date_range' as TypeEnum,
    Tariff: 'tariff' as TypeEnum,
    Boolean: 'boolean' as TypeEnum,
    ConfigIot: 'config_iot' as TypeEnum,
    File: 'file' as TypeEnum,
    Image: 'image' as TypeEnum,
    Pricing: 'pricing' as TypeEnum,
  };
}
