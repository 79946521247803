import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { UplotSeriesConfig } from '../../../../../shared/components/graph-uplot/graph/models/UplotSeriesConfig.model';
import { map, Observable, of, Subject, tap } from 'rxjs';
import { DataDisplayDefaultRanges } from '../../../../../shared/models/DataDisplayDefaultRanges';
import { DashboardItem } from '../../../models/DashboardItem';
import { RefreshValue } from '../dashboard-graph.component';
import { DataServiceProvider } from '../../../../data/api-data.service';
import { DashboardService } from '../../../services/dashboard.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SeriesGraphSettingsModel } from '../../../models/SeriesGraphSettings.model';
import { DashboardItemDataGraph } from '../../../models/DashboardItemDataGraph.model';
import { deepEquals } from '../../../../../shared/utils/deepEquals';
import { getSeriesConfig, getSeriesForRequest } from '../seriesUtils';
import { UplotInputData } from '../../../../../shared/components/graph-uplot/graph/models/UplotInputData.model';
import { DataRequestDto } from '../../../../../../api-main';
import { UplotResponseData } from '../../../../../shared/components/graph-uplot/graph/models/UplotResponseData.model';
import { formatDataByUnit } from '../../../../data/formatDataByUnit';
import { NgIf } from '@angular/common';
import { UplotRangePickerComponent } from '../../../../../shared/components/graph-uplot/uplot-range-picker/uplot-range-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { UplotRange } from '../../../../../shared/components/graph-uplot/graph/models/UplotRange.model';
import { getRangeFromDataDisplayDefaultRanges } from '../../../../../shared/utils/getRangeFromDataDisplayDefaultRanges';
import { HeatMapComponent } from '../../../../../shared/components/graph-uplot/heat-map/heat-map.component';

@Component({
  selector: 'app-dashboard-heatmap',
  standalone: true,
  imports: [HeatMapComponent, NgIf, UplotRangePickerComponent, TranslateModule],
  templateUrl: './dashboard-heatmap.component.html',
  styleUrl: './dashboard-heatmap.component.scss',
})
export class DashboardHeatmapComponent implements AfterViewInit {
  @ViewChild('graphComponent') graphRef: HeatMapComponent;

  // refreshGraph$: Subject<void> = new Subject<void>();
  noData: boolean = false;

  public downloadedSeries: Array<UplotSeriesConfig>;

  seriesConfig$: Subject<UplotSeriesConfig[]> = new Subject<UplotSeriesConfig[]>();

  private lastSavedConfig: UplotSeriesConfig[];

  private userRange: DataDisplayDefaultRanges;

  range: UplotRange;

  @Input({ required: true }) item: DashboardItem;

  @Input({ required: true }) refresh$: Observable<RefreshValue>;

  graphRefresh$ = new Subject<void>();

  constructor(
    private dataService: DataServiceProvider,
    protected dashboardService: DashboardService,
    protected cd: ChangeDetectorRef,
  ) {}

  private unsubscribe = takeUntilDestroyed();

  ngAfterViewInit() {
    this.range = this.getCurrentRange();
    this.cd.detectChanges();
    // console.log('=>(dashboard-heatmap.component.ts:74) this.range', this.range);

    this.refresh$.pipe(this.unsubscribe).subscribe((val: string) => {
      switch (val) {
        case 'series':
          this.updateSeries();
          break;
        // case 'range':
        //   this.updateRange();
        //   break;
        case 'graph':
          this.refreshGraph();
          break;
        default:
          this.refreshOnChanges();
      }
    });
  }

  private getCurrentRange() {
    return this.data.userDefinedRange != DataDisplayDefaultRanges.CUSTOM
      ? getRangeFromDataDisplayDefaultRanges(this.data.userDefinedRange)
      : this.data.range;
  }

  get series(): Array<SeriesGraphSettingsModel> {
    return this.data?.series ?? [];
  }

  get data(): DashboardItemDataGraph {
    return this.item.data?.graph;
  }

  changeRange(range: UplotRange, skipUpdate = false) {
    // console.log('=>(dashboard-heatmap.component.ts:109) range', range, skipUpdate);
    this.userRange = DataDisplayDefaultRanges.CUSTOM;

    const insignificantDiff = 1000;

    if (
      range.max < range.min ||
      (Math.abs(this.range.max - range.max) < insignificantDiff &&
        Math.abs(this.range.min - range.min) < insignificantDiff)
    )
      return;
    this.range = range;
    this.cd.detectChanges();
    if (skipUpdate) return;
    // console.log('refresh by range');
    this.triggerGraphRefresh();
    // this.updateRange();
  }

  private refreshOnChanges() {
    if (!this.downloadedSeries) return;
    const changes = !deepEquals(
      this.lastSavedConfig,
      getSeriesConfig(this.series, this.downloadedSeries),
    );
    if (!changes) return;
    setTimeout(() => {
      this.refreshGraph();
    }, 100);
  }

  updateSeries() {
    this.lastSavedConfig = getSeriesConfig(this.series, this.downloadedSeries);
    this.seriesConfig$.next(this.lastSavedConfig);
    this.cd.detectChanges();
    // this.graphRefresh$.next();
  }

  refreshGraph() {
    this.cd.detectChanges();
    this.updateRange();
    // this.updateSeries();
    this.triggerGraphRefresh();
  }

  private triggerGraphRefresh() {
    this.graphRefresh$.next();
  }

  updateRange() {
    if (this.data.userDefinedRange == null) return;
    if (this.data.userDefinedRange == DataDisplayDefaultRanges.CUSTOM) this.range = this.data.range;
    if (this.userRange == this.data.userDefinedRange) return;
    this.userRange = this.data.userDefinedRange;
    if (this.userRange != DataDisplayDefaultRanges.CUSTOM)
      this.range = getRangeFromDataDisplayDefaultRanges(this.userRange);
    this.cd.detectChanges();
    // this.graphRefresh$.next();
  }

  public getData: UplotInputData = (
    currentScale: DataRequestDto.ScaleEnum,
    range,
  ): Observable<UplotResponseData> => {
    const request: DataRequestDto = {
      min: Math.floor(range.min),
      max: Math.ceil(range.max),
      scale: currentScale,
      series: getSeriesForRequest(this.data.series),
      dashboard_id: this.dashboardService.dashboard?.id,
    };

    if (request.series.length == 0) return of({ data: [[range.min, range.max]], series: [] });

    return <Observable<UplotResponseData>>(<unknown>this.dataService.getData(request).pipe(
      tap((response: any) => this.setDownloadSeriesConfig(response.series)),
      map((response: UplotResponseData) =>
        formatDataByUnit({ data: response.data, series: this.series }, response.series),
      ),
      //TODO DELETE ITS TEMPORARY DATA RANDOMIZER
      // map((response: UplotResponseData) => {
      //   if (!isDevMode()) return response;
      //   response.data = response.data.map((o, i) => {
      //     if (i == 0) return o;
      //     return o.map((o2) => Math.random() * o2);
      //   });
      //   return response;
      // }),
      map((response: any) => {
        return {
          data: response.data,
          series: getSeriesConfig(this.series, this.downloadedSeries),
        };
      }),
    ));
    // .pipe(tap((response: any) => console.log('response:', response))));
  };

  private setDownloadSeriesConfig(seriesConfig: UplotSeriesConfig[]) {
    this.downloadedSeries = seriesConfig;
    this.lastSavedConfig = getSeriesConfig(this.series, this.downloadedSeries);
  }

  itemLoaded() {
    this.dashboardService.itemLoaded(this.item);
  }
}
