import { Component } from '@angular/core';
import { WaitService } from '../../../../../shared/services/wait.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrganizationService } from '../../../../../pages/organization/organization.service';
import { AlertService } from '../../../../../shared/services/alert.service';
import { FormBuilder } from '@angular/forms';
import { OrganizationDto } from '../../../../../../api-main';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ModalComponent } from '../../../../../shared/components/dialogs/modal/modal.component';
import { OrganizationSaveModalComponent } from '../../../../../pages/organization/organization-save-modal/organization-save-modal.component';
import { take } from 'rxjs';
import { SkeletonComponent } from '../../../../../shared/components/skeleton/skeleton.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-organization-select-modal',
  templateUrl: './organization-select-modal.component.html',
  styleUrls: ['./organization-select-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    MatSortModule,
    MatTableModule,
    NgIf,
    MatPaginatorModule,
    MatButtonModule,
    TranslateModule,
    SkeletonComponent,
    MatIcon,
  ],
})
export class OrganizationSelectModalComponent {
  readonly displayedColumns = ['logo', 'company_name', 'vat_number', 'role'];

  protected organizations: any;

  constructor(
    protected waitService: WaitService,
    private organizationService: OrganizationService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<OrganizationSelectModalComponent>,
    private dialog: MatDialog,
  ) {
    this.init();
  }

  init() {
    this.waitService.start();
    this.organizationService.list().subscribe((res) => {
      this.organizations = res.data;
      this.waitService.stop();
    });
  }

  selectNoneOrganization() {
    this.dialogRef.close({ id: 0 });
  }

  organizationSelected(organization: OrganizationDto) {
    this.dialogRef.close(organization);
  }

  openAddDialog(name: string = null) {
    // console.log('add dialog');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = Object.assign({}, { company_name: name });
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(OrganizationSaveModalComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: any) => {
        if (!data) return;
        this.waitService.start();
        this.organizationService.list().subscribe((res) => {
          this.organizations = res.data;
          this.waitService.stop();
        });
      });
  }

  protected getSaveButtonName() {
    return 'ORGANIZATION.CREATE_ORGANIZATION';
  }

  protected getCancelButtonName() {
    return 'ORGANIZATION.DONT_USE_ANY';
  }
}
