<section>
  <span class="node-name-header">{{ dashboardName$ | async }}</span>
  <div class="dashboard-buttons">
    <app-dashboard-templates-select
      *ngIf="allowEdit && showTemplateSelect()"
    ></app-dashboard-templates-select>
    <app-dropdown [customStyles]="dropDownStyles" position="right">
      <button
        [disabled]="isDownloading"
        mat-flat-button
        class="button-primary dropdown-button">
        <mat-icon svgIcon="download"></mat-icon>
        Download as...
      </button>
      <ng-container *ngIf="!isDownloading" body>
        <a (click)="downloadPng()">
          <mat-icon svgIcon="image"></mat-icon>
          PNG
        </a>
        <a (click)="downloadCsv()">
          <mat-icon svgIcon="table"></mat-icon>
          CSV
        </a>
        <a (click)="downloadPdf()">
          <mat-icon svgIcon="document"></mat-icon>
          PDF
        </a>
      </ng-container>
    </app-dropdown>
    @if (editMode) {
      <button mat-flat-button class="button-secondary" (click)="restore()">
        {{ 'DIALOG.BUTTONS.CANCEL' | translate }}
        <mat-icon iconPositionEnd>cancel</mat-icon>
      </button>

      <button
        mat-flat-button
        class="button-primary"
        [disabled]="!changesMade"
        (click)="toggleEditMode()"
      >
        {{ 'DASHBOARD.SAVE' | translate }}
        <mat-icon iconPositionEnd svgIcon="ok"></mat-icon>
      </button>
    } @else {
      <button
        *ngIf="allowEdit"
        mat-flat-button
        class="button-primary"
        (click)="toggleEditMode()"
        [disabled]="isDownloading"
      >
        {{ 'DASHBOARD.EDIT' | translate }}
        <mat-icon iconPositionEnd svgIcon="edit2"></mat-icon>
      </button>
    }

    <button
      *ngIf="allowEdit && showSaveAsTemplate()"
      mat-flat-button
      class="button-primary"
      (click)="openSaveTemplateModal()"
    >
      {{ 'DASHBOARD.SAVE_AS_TEMPLATE' | translate }}
      <mat-icon iconPositionEnd svgIcon="ok"></mat-icon>
    </button>
  </div>
</section>
