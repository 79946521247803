import { getPrefix } from './getProperPrefix';
import { getAllBaseUnitsForSeries } from '../utils/getBaseUnitForScale';
import { UplotSeriesConfig } from '../models/UplotSeriesConfig.model';

let formatFunction: (any) => string;
export function addPrefixes(v) {
  const { value, prefix } = getPrefix(v);

  return formatFunction(value) + prefix;
}
const labelsColor = '#637381';
const gridColor = '#E9ECEE';

const gridTickConfig = {
  width: 1 / devicePixelRatio,
  stroke: gridColor,
};

export function uplotGetAxes(_formatFunction: any, dateFormat, series: UplotSeriesConfig[] = null) {
  formatFunction = _formatFunction;

  const allUnits = series ? getAllBaseUnitsForSeries() : [];
  const axes = [
    {
      space: 150,
      // labelGap: 100,
      stroke: labelsColor,
      grid: gridTickConfig,
      ticks: gridTickConfig,
      values: (u, vals) => vals.map((v) => dateFormat(v * 1000)),
    },
    ...allUnits.map((o, i) => getCustomAxis(o, i)),
    ...(allUnits.length == 0
      ? [
          {
            size: 60,
            stroke: labelsColor,
            grid: gridTickConfig,
            ticks: gridTickConfig,
            values: (u, val) => {
              return val.map(addPrefixes);
            },
          },
        ]
      : []),
  ];

  return axes;
}

export function getCustomAxis(o: string, i: number) {
  return {
    show: true,
    label: o,
    scale: o,
    size: 50,
    side: 3 - (i % 2) * 2,
    stroke: labelsColor,
    grid: gridTickConfig,
    ticks: gridTickConfig,
    values: (u, val) => {
      return val.map(addPrefixes);
    },
  };
}
