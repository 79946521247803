import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, filter, map, skip } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UplotRange } from '../../../../../shared/components/graph-uplot/graph/models/UplotRange.model';
import { LocaleSessionService } from '../../../../../shared/services/localeSession.service';
import { getRangeString } from '../../../../../shared/components/graph-uplot/graph/utils/uplotUtil';

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    NgIf,
    TranslateModule,
    MatNativeDateModule,
  ],
  providers: [MatDatepickerModule, MatNativeDateModule],
})
export class RangePickerComponent {
  private blockRangeChangeTrigger = false;

  formFocused: boolean = false;

  rangeForm = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  // @Input() currentRange: UplotRange;

  @Input() mod = 1e3;

  private _currentRange: UplotRange;

  @Input() set currentRange(range: UplotRange) {
    if (!range) return;
    // console.log('set range picker range', range);

    this.blockRangeChangeTrigger = true;
    this._currentRange = range;

    this.rangeForm.controls.start.setValue(range ? new Date(range.min * this.mod) : null);
    this.rangeForm.controls.end.setValue(range ? new Date(range.max * this.mod) : null);
    setTimeout(() => (this.blockRangeChangeTrigger = false), 100);
  }

  get currentRange(): UplotRange {
    return this._currentRange;
  }

  @Input() disabled: boolean;

  @Output() rangeHasChanged = new EventEmitter<UplotRange>();

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private localeSession: LocaleSessionService,
  ) {
    this.dateAdapter.setLocale(localeSession.locale); //dd/MM/yyyy
    this.rangeForm.valueChanges
      .pipe(
        // filter((o) => o.start != null && o.end != null),
        skip(1),
        map(() => ({
          min: this.rangeForm.controls.start.value
            ? new Date(this.rangeForm.controls.start.value)
            : null,
          max: this.rangeForm.controls.end.value
            ? new Date(this.rangeForm.controls.end.value)
            : null,
        })),
        takeUntilDestroyed(),
        debounceTime(100),
        filter(() => !this.blockRangeChangeTrigger || this.formFocused),
      )
      .subscribe(() => {
        // console.log('form change, trigger range change', change);
        this.rangeChanged();
      });
  }

  rangeChanged() {
    const [min, max] = [
      this.rangeForm.controls.start.value ? new Date(this.rangeForm.controls.start.value) : null,
      this.rangeForm.controls.end.value ? new Date(this.rangeForm.controls.end.value) : null,
    ];

    if (!min || !max) return;

    min.setHours(0, 0, 0);
    max.setHours(24, 0, -1);

    const range = { min: min.getTime() / this.mod, max: max.getTime() / this.mod };
    this.currentRange = range;
    this.rangeHasChanged.emit(range);
  }

  protected readonly getRangeString = getRangeString;
}
