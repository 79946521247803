import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OrganizationInvitationRequest } from '../../../api-main';
import { AlertService } from '../../shared/services/alert.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TreeService } from '../../pages/tree/tree.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationService } from '../../pages/organization/organization.service';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ModalComponent } from '../../shared/components/dialogs/modal/modal.component';
import { EmuInputComponent } from '../../shared/components/inputs/emu-input/emu-input.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    TranslateModule,
    EmuInputComponent,
  ],
})
export class InvitationsComponent {
  blockInput = false;

  code: string = '';

  constructor(
    private alert: AlertService,
    private translate: TranslateService,
    private treeService: TreeService,
    private dialogRef: MatDialogRef<InvitationsComponent>,
    private organizationService: OrganizationService,
    @Inject(MAT_DIALOG_DATA) public data: { code: string },
  ) {
    if (data?.code !== undefined) this.setCode(data.code);
  }

  isCodeValid(): boolean {
    if (this.code === undefined || this.code === '' || this.code === null) {
      return false;
    }

    return this.code.toString().startsWith('o-') || this.code.toString().startsWith('p-');
  }

  onSubmit() {
    if (!this.isCodeValid()) return;
    this.blockInput = true;
    const codeReqObj: OrganizationInvitationRequest = { code: this.code };

    if (this.isOrganizationInvite(this.code)) this.organizationInvite(codeReqObj);
    else if (this.isPolicyInvite(this.code)) this.gpInvite(codeReqObj);
    else this.showInvalidInvitationAlert();
  }

  close() {
    this.dialogRef.close();
  }

  private isPolicyInvite(code) {
    return code.startsWith('p-');
  }

  private isOrganizationInvite(code) {
    return code.startsWith('o-');
  }

  private organizationInvite(code: OrganizationInvitationRequest) {
    this.organizationService
      .acceptInvitation(code)
      .pipe(finalize(() => (this.blockInput = false)))
      .subscribe({
        next: (res) => {
          this.checkStatusAndShowAlertOnSuccess(res);
        },
      });
  }

  private gpInvite(code: OrganizationInvitationRequest) {
    this.treeService
      .gpControllerGpInviteAccept(code)
      .pipe(finalize(() => (this.blockInput = false)))
      .subscribe({
        next: (res) => this.checkStatusAndShowAlertOnSuccess(res),
      });
  }

  private checkStatusAndShowAlertOnSuccess(res) {
    if (!(res.status === 200 || res == true || res.code == 200)) {
      this.alert.error(
        this.translate.instant(res.error.message ?? 'NOTIFICATION.SOMETHING_WENT_WRONG'),
      );
    } else {
      this.alert.info(this.translate.instant('NOTIFICATION.MEMBERSHIP_ACCEPTED'));
      this.close();
    }
  }

  private resetForm() {
    this.code = '';
  }

  private showInvalidInvitationAlert() {
    this.alert.error(this.translate.instant('INVALID_INVITATION_CODE_ALERT'));
  }

  getTitle() {
    return 'INVITATION.INVITATION_CODE';
  }

  getSaveButtonName() {
    return 'USE'; // 'DIALOG.BUTTONS.SAVE';
  }

  getCancelButtonName() {
    return 'CREATE_DIALOG.BUTTONS.CANCEL';
  }

  setCode(code: string) {
    this.code = code;
  }

  onPaste(event: any) {
    let paste = event.clipboardData.getData('text');
    paste = paste.trim();
    setTimeout(() => this.setCode(paste), 100);
  }
}
