<div class="select-container">
  <app-emu-list-input
    #loggerList
    (listOpened)="listState$.next(true)"
    (listClosed)="listState$.next(false)"
    [loading$]="loading$"
    (valueChange)="loggerInput$.next($event)"
    [value]="selectedLogger"
    [name]="'LOGGER.NAME_INPUT'"
    (optionSelected)="selectedLogger = $event"
    [displayWith]="displayLoggerFn.bind(this)"
    [filteredOptions$]="logger$"
    (optionCleared)="resetValues()"
    [className]="'dashboard-settings no-wrap'"
  >
    <ng-template let-item>
      <p class="device-name no-wrap">{{ item.name }}</p>
      <p class="last-connection">
        {{ 'LOGGER.LAST_CONNECTION' | translate }}:
        <app-timestamp-relative
          [time]="item.last_connection"
          [showRelative]="true"
        ></app-timestamp-relative>
      </p>
    </ng-template>
  </app-emu-list-input>
</div>
