/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DashboardCyclicReportDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  id?: string;
  /**
   * report_name
   */
  report_name: string;
  /**
   * dashboard_name
   */
  readonly dashboard_name?: string;
  /**
   * emails
   */
  emails: Array<string>;
  /**
   * dashboard_id
   */
  dashboard_id: number;
  /**
   * origin
   */
  readonly origin?: string;
  /**
   * lng
   */
  lng: string;
  /**
   * start_at
   */
  start_at: string;
  /**
   * end_at
   */
  end_at: string;
  /**
   * interval
   */
  interval: number;
  /**
   * interval_type
   */
  interval_type: DashboardCyclicReportDto.IntervalTypeEnum;
}
export namespace DashboardCyclicReportDto {
  export type IntervalTypeEnum = 'MINUTE' | 'HOUR' | 'DAY';
  export const IntervalTypeEnum = {
    MINUTE: 'MINUTE' as IntervalTypeEnum,
    HOUR: 'HOUR' as IntervalTypeEnum,
    DAY: 'DAY' as IntervalTypeEnum,
  };
}
