import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { LocaleSessionService } from '../../../services/localeSession.service';
import { UplotRange } from '../graph/models/UplotRange.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { concat, debounceTime, filter, skip, take } from 'rxjs';
import { getRangeString } from '../graph/utils/uplotUtil';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-uplot-range-picker',
  templateUrl: './uplot-range-picker.component.html',
  styleUrls: ['./uplot-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    NgIf,
    TranslateModule,
    MatNativeDateModule,
  ],
  providers: [MatDatepickerModule, MatNativeDateModule],
})
export class UplotRangePickerComponent {
  private blockRangeChangeTrigger = false;

  formFocused: boolean = false;

  rangeForm = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  // @Input() currentRange: UplotRange;

  private _currentRange: UplotRange;

  @Input() set currentRange(range: UplotRange) {
    // console.log('set range picker range', range);
    this.blockRangeChangeTrigger = true;
    //to prevent refresh
    // this.prevRange = range;
    const updateForm = !this.isChangeInsignificant(range, this.currentRange);
    this._currentRange = range;

    if (updateForm) {
      this.rangeForm.controls.start.setValue(range ? new Date(range.min * 1000) : null);
      this.rangeForm.controls.end.setValue(range ? new Date(range.max * 1000) : null);
    }
    setTimeout(() => (this.blockRangeChangeTrigger = false), 100);
  }

  get currentRange(): UplotRange {
    return this._currentRange;
  }

  @Input() disabled: boolean;

  @Output() rangeHasChanged = new EventEmitter<UplotRange>();

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private localeSession: LocaleSessionService,
  ) {
    this.dateAdapter.setLocale(localeSession.locale); //dd/MM/yyyy
    const startObserving$ = this.rangeForm.valueChanges.pipe(
      filter((o) => o.start != null && o.end != null),
      take(1),
    );

    concat(startObserving$, this.rangeForm.valueChanges)
      .pipe(
        // filter((o) => o.start != null && o.end != null),
        skip(1),
        takeUntilDestroyed(),
        debounceTime(100),
        filter(() => !this.blockRangeChangeTrigger || this.formFocused),
      )
      .subscribe(() => {
        // console.log('form change, trigger range change', change);
        this.rangeChanged();
      });

    //observe range

    // timer(0, 100)
    //   .pipe(takeUntilDestroyed())
    //   .subscribe(() => {
    //     this.checkInput();
    //   });
  }

  private isChangeInsignificant(range: UplotRange, prevRange: UplotRange): boolean {
    const insignificantDiff = 1000;
    return (
      range != null &&
      prevRange != null &&
      Math.abs(prevRange.min - range.min) < insignificantDiff &&
      Math.abs(prevRange.max - range.max) < insignificantDiff
    );
  }

  rangeChanged() {
    if (!this.currentRange || this.formFocused) return;

    const [min, max] = [
      new Date(this.rangeForm.controls.start.value),
      new Date(this.rangeForm.controls.end.value),
    ];

    if (
      this.rangeForm.controls.start.value == null ||
      this.rangeForm.controls.end.value == null ||
      this.isChangeInsignificant(this.currentRange, {
        min: min.getTime() / 1e3,
        max: max.getTime() / 1e3,
      })
    )
      return;

    // console.log('=>(uplot-range-picker.component.ts:106) min, max', min, max);

    // min.setUTCHours(0 - getTimezoneOffsetInHours(this.localeSession.timezone, min), 0, 0);
    // max.setUTCHours(24 - getTimezoneOffsetInHours(this.localeSession.timezone, min), 0, -1);

    min.setHours(0, 0, 0);
    max.setHours(24, 0, -1);

    const range = { min: min.getTime() / 1000, max: max.getTime() / 1000 };
    this.rangeHasChanged.emit(range);
  }

  protected readonly getRangeString = getRangeString;
}
